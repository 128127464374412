import React from "react";
import {TextField} from "@mui/material";

/**
 * Muestra un input de contraseña
 *
 * ## PROPS
 * - **label** *string*
 * - **name** *string*
 * - **value** *string*
 * - **onChange** *function*
 * - **required** *bool*
 */
export default function InputPassword(props) {

    const {label, name, value, onChange, required} = props

    return (
        <TextField
            type="password"
            variant="outlined"
            className="form-control"
            size="small"
            required={required}
            fullWidth
            inputProps={{
                autoComplete: "autocomplete=\"ÑÖcompletes\"",
                form: {
                    autoComplete: "off",
                },
            }}
            label={label}
            name={name}
            value={value}
            onChange={onChange}
        />
    )
}
