import React from "react";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Fill1 from "../../../assets/uploads/productPaok/Fill1.svg";
import icon from "../../../assets/uploads/productPaok/Fill_1.svg";

export default function Block2() {

    return (
        <div className="block2-product-paok px-md-5-34 px-lg-10-42">
            <Grid container className="grid-container">
                <Grid item className="grid-item">
                    <img src={Fill1} className="key-image" />
                </Grid>
                <Grid item className="grid-item">
                    <Grid container className="list-container list-container-notlast" sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        alignItems: "start",
                        justifyContent: "flex-start"
                    }}>
                        <Grid item className="icon-product-container">
                            <img src={icon} className="icon-product" />
                        </Grid>
                        <Grid item>
                            <span>Llave de alta seguridad</span>
                        </Grid>
                    </Grid>
                    <Grid container className="list-container list-container-notlast" sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        alignItems: "start",
                        justifyContent: "flex-start"
                    }}>
                        <Grid item className="icon-product-container">
                            <img src={icon} className="icon-product" />
                        </Grid>
                        <Grid item>
                            <span>16 puntos de anclaje</span>
                        </Grid>
                    </Grid>
                    <Grid container className="list-container list-container-notlast" sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        alignItems: "start",
                        justifyContent: "flex-start"
                    }}>
                        <Grid item className="icon-product-container">
                            <img src={icon} className="icon-product" />
                        </Grid>
                        <Grid item>
                            <span>Homologada y certificada de grado 4</span>
                        </Grid>
                    </Grid>
                    <Grid container className="list-container list-container-notlast" sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        alignItems: "start",
                        justifyContent: "flex-start"
                    }}>
                        <Grid item className="icon-product-container">
                            <img src={icon} className="icon-product" />
                        </Grid>
                        <Grid item>
                            <span>Anti magic key</span>
                        </Grid>
                    </Grid>
                    <Grid container className="list-container list-container-last" sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        alignItems: "start",
                        justifyContent: "flex-start"
                    }}>
                        <Grid item className="icon-product-container">
                            <img src={icon} className="icon-product" />
                        </Grid>
                        <Grid item>
                            <span>Anti bumping</span>
                        </Grid>
                    </Grid>
                    <Link href="/contact" sx={{
                        textDecoration: "none"
                    }}>
                        <Button variant="contained" className="btn-contained-blue">Infórmese</Button>
                    </Link>
                </Grid>
            </Grid>
        </div>
    )

}