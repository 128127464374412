import React from "react";
import Button from "@mui/material/Button";
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';

export default function Block5() {

    return (
        <div className="block5-product-gravity px-md-5-34 px-lg-10-42">
            <Grid container className="grid-container">
                <Grid item xs={6} className="grid-item">
                    <p className="mt-0">Sí, <span className="paragraph-bold">Gravity</span> es <br />la cerradura total</p>
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <Link href="/contact" sx={{
                        textDecoration: "none"
                    }}>
                        <Button variant="outlined" className="btn-primary-white">Contáctenos</Button>
                    </Link>
                </Grid>
            </Grid>
        </div>
    )

}