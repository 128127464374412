import React, {useEffect, useState} from "react";
import {apiDelete, apiList} from "../../../../services/Admin/contact";
import moment from "moment";
import {Button, Checkbox, Grid, Paper} from "@mui/material";
import MsgError from "../../../../components/MsgError";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import Table from "../../../../components/Table";
import {DASH_MAX_ROW} from "../../../../utils/constants";
import Modal from "../../../../components/Modal";
import MsgSuccess from "../../../../components/MsgSuccess";

export default function Management() {
    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [loading, setLoading] = useState(true)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [open, setOpen] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [selectItem, setSelectItem] = useState({
        id: "",
        name: "",
        surname: "",
        phone: "",
        email: "",
        company: "",
        timeContact: "",
        text: "",
        newsletter: "",
        dateCreate: ""
    })

    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getItemsData = () => {
        setMsgError([])

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field
        if (state.sort.field === "roleName") { field = "role" }
        if (state.sort.field === "active") { field = "isActive" }
        if (state.sort.field === "access") { field = "lastActivity" }

        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort
        }

        if (state.email) { params.email = state.email }
        if (state.role) { params.role = state.role }

        apiList(params).then(resp => {
            setRowCount(resp.data?.count)

            let temp = resp.data.data?.map(doc => {
                return {
                    ...doc,
                    newsletter: doc.newsletter ? "Si" : "No",
                    dateCreate: moment.unix(doc.dateCreate).format("D/M/Y HH:mm"),
                }
            })

            setRows(temp)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setRows([])
        })
    }

    const handleDelete = () => {
        setLoadingDelete(true)
        setMsgSuccess([])
        setMsgError([])

        apiDelete(selectItem?.id).then(resp => {
            setMsgSuccess(resp?.data?.msg)
            setLoadingDelete(false)
            getItemsData()
            setOpenDelete(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoadingDelete(false)
            setOpenDelete(false)
        })
    }

    return (
        <>
            <Modal
                title="Contacto"
                btnCancel="Cerrar"
                open={open}
                setOpen={setOpen}
            >
                <CardContact props={selectItem} />
            </Modal>

            <Modal
                title="Eliminar"
                btnOk="Eliminar"
                btnCancel="Cancelar"
                open={openDelete}
                setOpen={setOpenDelete}
                handleClick={handleDelete}
                loading={loadingDelete}
            >
                <p>Esta seguro que desea eliminar este Contacto?</p>
            </Modal>

            <HeaderPage title="Contactos" />

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <MsgError msg={msgError}/>
                    <MsgSuccess msg={msgSuccess}/>
                </Grid>

                <Grid item xs={12}>

                    <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>

                        <Table
                            columns={tableColumns(setOpen, setSelectItem, setOpenDelete)}
                            rows={rows}
                            rowCount={rowCount}
                            filter={state}
                            setFilter={setState}
                            loading={loading}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        offset: 0,
        sort: { field: "id", sort: "desc" }
    }
}

function tableColumns(setOpen, setSelectItem, setOpenDelete) {
    return [
        {
            field: "id",
            headerName: "ID",
        },
        {
            field: "name",
            headerName: "NOMBRE",
        },
        {
            field: "surname",
            headerName: "APELLIDO",
            width: 200,
        },
        {
            field: "phone",
            headerName: "TELÉFONO MÓVIL",
        },
        {
            field: "email",
            headerName: "EMAIL",
            width: 150,
        },
        {
            field: "company",
            headerName: "EMPRESA",
            width: 200,
        },
        {
            field: "timeContact",
            headerName: "HORARIO SOLICITUD LLAMADA",
            width: 200,
        },
        {
            field: "text",
            headerName: "COMENTARIOS",
            width: 400,
        },
        {
            field: "newsletter",
            headerName: "NEWSLETTER",
            width: 100,
        },
        {
            field: "dateCreate",
            headerName: "FECHA CREACIÓN",
            width: 200,
        },
        {
            field: "actions",
            headerName: "ACCIONES",
            width: 200,
            renderCell: (params) => {
                return (
                    <>
                        <Button onClick={() => { setOpen(true); setSelectItem({
                                id: params.row.id,
                                name: params.row.name,
                                surname: params.row.surname,
                                phone: params.row.phone,
                                email: params.row.email,
                                company: params.row.company,
                                timeContact: params.row.timeContact,
                                text: params.row.text,
                                newsletter: params.row.newsletter,
                                dateCreate: params.row.dateCreate
                            }) }}
                        >Mostrar</Button>

                        <Button onClick={() => { setOpenDelete(true); setSelectItem({
                                id: params.row.id,
                                name: params.row.name,
                                surname: params.row.surname,
                                phone: params.row.phone,
                                email: params.row.email,
                                company: params.row.company,
                                timeContact: params.row.timeContact,
                                text: params.row.text,
                                newsletter: params.row.newsletter,
                                dateCreate: params.row.dateCreate
                            }) }}
                        >Eliminar</Button>
                    </>
                )
            },
        },
    ]
}

function CardContact (props) {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <p><string style={{fontWeight: 'bold'}}>ID:</string> {props?.props?.id}</p>
                <p><string style={{fontWeight: 'bold'}}>Nombre:</string> {props?.props?.name}</p>
                <p><string style={{fontWeight: 'bold'}}>Apellido:</string> {props?.props?.surname}</p>
                <p><string style={{fontWeight: 'bold'}}>Teléfono móvil:</string> {props?.props?.phone}</p>
                <p><string style={{fontWeight: 'bold'}}>Email:</string> {props?.props?.email}</p>
                <p><string style={{fontWeight: 'bold'}}>Empresa:</string> {props?.props?.company}</p>
                <p><string style={{fontWeight: 'bold'}}>Horario solicitud llamada:</string> {props?.props?.timeContact}</p>
                <p><string style={{fontWeight: 'bold'}}>Newsletter:</string> {props?.props?.newsletter}</p>
                <p><string style={{fontWeight: 'bold'}}>Fecha creación:</string> {props?.props?.dateCreate}</p>
                <p><string style={{fontWeight: 'bold'}}>Datos y comentarios importantes para nuestro equipo:</string></p>
                <p>{props?.props?.text}</p>
            </Grid>
        </Grid>
    )
}

