import React from "react";
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import Link from '@mui/material/Link';
import imgGroup9 from "../../../assets/uploads/Group_9.png"

export default function Carusel5() {

    return (
        <div className="carousel5 px-md-20px px-lg-150px py-md-60px element-view-height h-xs-auto pt-lg-124px">
            <Grid container sx={{
                height: "100%"
            }}
            className="carousel5-grid-container">
                <Grid item xs={12} lg={6} sx={{
                    display: "flex",
                    alignContent: "flex-start",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    flexWrap: "nowrap"
                }}>
                    <h2 className="title">Soluciones <span>de principio a fin.</span></h2>
                    <p className="first-text">Cubrimos todas las fases del proceso, desde la <span>fabricación</span> hasta la <span>gestión</span> y <span>análisis de los datos</span> recogidos y almacenados en la nube.</p>
                    <p className="second-text">Así, usted solo tendrá que preocuparse de disfrutar de su seguridad.</p>
                    <Link href="/contact" sx={{
                        textDecoration: "none"
                    }}>
                        <Button variant="outlined" className="btn-four-blue btn-carousel5">Conózcanos</Button>
                    </Link>
                </Grid>
                <Grid item xs={12} lg={6} sx={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    flexWrap: "nowrap"
                }} className="carousel5-img">
                    <img src={imgGroup9} alet="imgGroup9" className="" alt=""/>
                </Grid>
            </Grid>
        </div>
    )
}