import React, {useCallback, useEffect, useState} from "react";
import StarterKit from '@tiptap/starter-kit'
import {EditorContent, useEditor} from '@tiptap/react'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Image from '@tiptap/extension-image'
import Dropcursor from '@tiptap/extension-dropcursor'
import {
    FaBold,
    FaHeading,
    FaImage,
    FaItalic,
    FaListOl,
    FaListUl,
    FaQuoteLeft,
    FaStrikethrough,
    FaUnderline
} from "react-icons/fa";

import SelectMedia from "./Admin/SelectMedia";

/**
 * Muestra un botón para subir ficheros
 *
 * ## PROPS
 * - **elementDnd** *object (useState)*   Ej: [{id: "1", content: "Item 1" }, {id: "2", content: "Item 2" }, {id: "1", content: "Item 3" }]
 * - **setElementDnd** *object (useState)*
 */
export default function EditorText(props) {
    const {content, setContent} = props
    const editor = useEditor({
        extensions: [
            StarterKit, Document, Paragraph, Text, Image, Dropcursor
        ],
        content: content,
        onUpdate: ({editor}) => {
            const html = editor.getHTML();
            setContent(html);
        },
    })

    if (!editor) {
        return null
    }

    return (
        <>
            <MenuBar editor={editor}/>
            <EditorContent editor={editor} style={{
                borderBottomRightRadius: "10px",
                borderBottomLeftRadius: "10px",
                border: "1px solid #bdbcbd"
            }}/>
        </>
    )
}

const MenuBar = ({editor}) => {

    const [image, setImage] = useState([])

    if (!editor) {
        return null
    }

    useEffect(() => {
        // Esperamos respuesta de media, para obtener el avatar
        if (image.length > 0){
            editor.chain().focus().setImage({src: image[0].url}).run()
            setImage([])
        }
    }, [image]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="menuBar">
            <div>
                <button
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    className={editor.isActive("bold") ? "is_active" : ""}
                >
                    <FaBold/>
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    className={editor.isActive("italic") ? "is_active" : ""}
                >
                    <FaItalic/>
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleUnderline().run()}
                    className={editor.isActive("underline") ? "is_active" : ""}
                >
                    <FaUnderline/>
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleStrike().run()}
                    className={editor.isActive("strike") ? "is_active" : ""}
                >
                    <FaStrikethrough/>
                </button>
                <button
                    onClick={() =>
                        editor.chain().focus().toggleHeading({level: 2}).run()
                    }
                    className={
                        editor.isActive("heading", {level: 2}) ? "is_active" : ""
                    }
                >
                    <FaHeading/>
                </button>
                <button
                    onClick={() =>
                        editor.chain().focus().toggleHeading({level: 3}).run()
                    }
                    className={
                        editor.isActive("heading", {level: 3}) ? "is_active" : ""
                    }
                >
                    <FaHeading className="heading3"/>
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleBulletList().run()}
                    className={editor.isActive("bulletList") ? "is_active" : ""}
                >
                    <FaListUl/>
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleOrderedList().run()}
                    className={editor.isActive("orderedList") ? "is_active" : ""}
                >
                    <FaListOl/>
                </button>
                <button
                    onClick={() => editor.chain().focus().toggleBlockquote().run()}
                    className={editor.isActive("blockquote") ? "is_active" : ""}
                >
                    <FaQuoteLeft/>
                </button>

            </div>
            <div>
                <SelectMedia
                    btnName={<FaImage/>}
                    setSelectElements={setImage}
                    selectElements={image}
                    selectedMultiple={false}
                />
            </div>
        </div>
    )
}
