import React from "react";
import Grid from '@mui/material/Grid';

export default function Carusel2() {

    return (
        <div className="carusel2-about px-md-20px px-lg-150px py-md-60px element-view-height h-xs-auto">
            <Grid container sx={{
                height: "100%",
                alignItems: "center",
                alignContent: "center",
                maxWidth: "1132px"
            }}>
                <Grid item xs={12} sx={{
                    marginBottom: "0px"
                }}>
                    <p><span>STM</span> es una empresa de <span>seguridad homologada</span>, con número 4251 en la Dirección General de Seguridad, fabricante de <span>todos los productos</span> que permiten <span>proteger</span> los activos Real Estate.</p>
                    <p>Ofrecemos una <span>solución integral y modular</span> que garantice la <span>seguridad y el control de accesos</span> de los grandes y pequeños <span>tenedores de inmuebles</span>.</p>
                </Grid>
            </Grid>
        </div>
    )
}