import React from "react";
import Grid from '@mui/material/Grid';
import productEsquema from "../../../assets/uploads/productDoor/Door_esquema_OK.png"

export default function Block4() {

    return (
        <div className="block4-product-door px-md-5-34 px-lg-10-42">
            <Grid container className="grid-container">
                <Grid item xs={12} lg={6} className="grid-item">
                    <img src={productEsquema} />
                </Grid>
                <Grid item xs={12} lg={6} className="grid-item">
                    <h2 className="mt-0"><span className="paragraph-thin">STM</span> Door, <br />la seguridad <br />se multiplica</h2>
                    <p className="margin-0">La tecnología STM está diseñada para aprovechar al máximo la interconectividad de todos los dispositivos y controlar sus activos en todo momento.</p>
                </Grid>
            </Grid>
        </div>
    )

}