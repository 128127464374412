import React, { useRef, useState } from "react";
import Block1 from "./Block1";
import Block2 from "./Block2";
import BasicFooter from "../../../components/Layouts/User/BasicFooter";

export default function Products() {
  
    return (
        <div className="products">
            <Block1 />
            <Block2 />
            <BasicFooter />
        </div>
    )
}
