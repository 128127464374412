import React from "react";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Mobile from "../../../assets/uploads/productDoor/iphone_web.png";

export default function Block2() {

    return (
        <div className="block2-product-door px-md-5-34 px-lg-10-42">
            <Grid container className="grid-container">
                <Grid item className="grid-item">
                    <img src={Mobile} className="mobile-image desktop-display" />
                </Grid>
                <Grid item className="grid-item">
                    <p className="mt-0">Desde la app 
                    disfrutará de un control total de los accesos y 
                    del estado de la <span className="paragraph-thin">STM</span> <span className="paragraph-bold">Door</span> cuándo quiera, cómo quiera y desde dónde quiera.</p>
                    <Link href="/contact" sx={{
                        textDecoration: "none"
                    }}>
                        <Button variant="contained" className="btn-contained-blue">Solicitar ficha técnica</Button>
                    </Link>
                </Grid>
            </Grid>
        </div>
    )

}