import React from "react";

export default function Block1() {

    return (
        <div className="aviso-legal px-md-5-34 px-lg-10-42 margin-top-navbar">
            AVISO LEGAL Y POLITICA DE PRIVACIDAD<br /><br />

            Esta página Web es propiedad de SOLUCIONES TECNICAS DEL METAL S.L. y tiene carácter meramente informativo acerca de los servicios que presta de diseño grafico y demás proyectos creativos. El hecho de acceder a esta página implica el conocimiento y aceptación de los siguientes términos y condiciones de uso.<br /><br />

            1. Titularidad de la página web. El nombre de dominio www.stmseguridad.com está registrado a favor de SOLUCIONES TECNICAS DEL METAL S.L., con CIF B63434633 y domicilio social en la Carretera de Santiga 86-90 08291 Ripollet, Barcelona. Teléfono de contacto 935.752.015 y dirección de correo electrónico info@stmseguridad.com. Los datos Registrales del dominio son: Registro Mercantil de Barcelona.<br /><br />

            2. Propiedad intelectual de la web. Todos los derechos de propiedad intelectual del contenido de esta página web y su diseño gráfico, son propiedad exclusiva de SOLUCIONES TECNICAS DEL METAL S.L., y es a quien le corresponde el ejercicio exclusivo de los derechos de explotación de los mismos. Así, queda prohibida su reproducción, distribución, comunicación pública y modificación, total o parcial, sin previa autorización expresa de SOLUCIONES TECNICAS DEL METAS S.L. De igual forma, todos los signos distintivos, marcas, nombres comerciales o signos de cualquier clase contenidos en esta página web están protegidos por ley.<br /><br />

            3. Contenido de la web y enlaces. SOLUCIONES TECNICAS DEL METAL S.L. no se responsabiliza del mal uso que se realice de los contenidos de su página web, siendo responsabilidad exclusiva de la persona que accede a ellos o los utiliza. Tampoco asume responsabilidad alguna por la información contenida en las páginas web de terceros a las que se pueda acceder a través de enlaces desde la página web. La presencia de estos enlaces tiene una finalidad informativa, y no constituyen en ningún caso una invitación a la contratación de productos o servicios que se puedan ofrecer en la página web de destino. En el caso de que SOLUCIONES TECNICAS DEL METAL S.L. tuviera conocimiento efectivo de que la actividad o la información a la que remiten estos enlaces es ilícita, constitutiva de delito o que puede lesionar bienes o derechos de terceros susceptibles de indemnización, actuará con la diligencia necesaria para suprimir o inutilizar el enlace correspondiente a la mayor brevedad posible.<br /><br />

            4. Intercambio o difusión de información. SOLUCIONES TECNICAS DEL METAL S.L. declina toda responsabilidad derivada del intercambio de información entre usuarios a través de su página web. Especialmente no nos hacemos responsables del uso que los menores puedan hacer de ella en caso de que los contenidos a que tengan acceso puedan herir su sensibilidad.<br /><br />

            5. Actualización y modificación de la página web. SOLUCIONES TECNICAS DEL METAL S.L. se reserva el derecho de actualizar, modificar o eliminar la información contenida en su página web, y su configuración o presentación, en cualquier momento, sin previo aviso y sin asumir ningún tipo de responsabilidad por hacerlo.<br /><br />

            6. Indicaciones sobre aspectos técnicos. SOLUCIONES TECNICAS DEL METAL S.L. no asume responsabilidad alguna derivada de problemas técnicos o fallos en los equipos informáticos, que se produzcan durante la conexión a Internet, o que puedan ser originados por terceras personas a través de intromisiones ilegítimas fuera de su control.<br /><br />

            Desde SOLUCIONES TECNICAS DEL METAL S.L. no se garantiza la ausencia de virus y otros elementos que puedan causar daños en los sistemas informáticos, los documentos electrónicos o los ficheros de usuario de esta página web o de páginas web de terceros, y no se responsabiliza de los daños y perjuicios que se puedan llegar a producir por estos motivos. De igual forma, no se responsabiliza ante posibles daños y perjuicios que puedan afectar al usuario como consecuencia de errores, defectos u omisiones en la información que facilita cuando procede de terceras fuentes.<br /><br />

            7. Cuando el usuario facilita sus datos de carácter personal utilizando nuestros formularios o e-mails de contacto, está autorizando expresamente a SOLUCIONES TECNICAS DEL METAL S.L. al tratamiento de sus Datos Personales para poder atender sus peticiones de servicios, enviarle comunicaciones comerciales o gestionar sus datos curriculares, según el caso. SOLUCIONES TECNICAS DEL METAL S.L. , incluirá los datos facilitados por el usuario en un fichero de su titularidad que contará con todas las medidas de seguridad necesarias y exigidas por la normativa vigente en materia de Protección de Datos. SOLUCIONES TECNICAS DEL METAL S.L. no cederá o comunicará a terceros los datos recabados sin previo consentimiento expreso del usuario, excepto en el caso que la comunicación sea necesaria para prestar el servicio que el usuario nos haya solicitado. El usuario podrá ejercitar sus derechos de acceso, rectificación, cancelación y oposición, dirigiéndose por escrito a SOLUCIONES TECNICAS DEL METAL S.L. , Responsable del Fichero, ubicado en la Carretera de Santiga 86-90 08291 Ripollet, Barcelona.<br /><br />

            8. Legislación y jurisdicción aplicable. Con carácter general, las relaciones con los usuarios, derivadas de la prestación de servicios contenidos en esta página web, están sometidos a la legislación y jurisdicción española. Los usuarios de esta página web son conscientes de todo lo que se ha expuesto y lo aceptan voluntariamente.<br /><br />

            <br /><br />

            POLÍTICA DE PRIVACIDAD<br /><br />

            Fecha de última actualización: 19 de octubre de 2023.<br /><br />

            1.	OBJETO<br /><br />

            La presente política de privacidad regula el tratamiento de datos personales que se realiza a través de la página web https://stmseguridad.com/ (en adelante, el “Sitio Web”).<br /><br />

            2.	RESPONSABLE DEL TRATAMIENTO<br /><br />

            El responsable del tratamiento de los datos personales que se realiza a través del Sitio Web es la sociedad Soluciones Técnicas del Metal S.L., con domicilio social avenida del Castell de Barberà, 27, 08210 Barberá del Vallès, NIF B63434633 y correo electrónico lopd@stmseguridad.com.<br /><br />

            Soluciones Técnicas del Metal S.L. se reserva el derecho de modificar la presente política de privacidad según su propio criterio, o motivado por un cambio legal, jurisprudencial o doctrinal de la Agencia Española de Protección de Datos. El uso del Sitio Web tras los cambios implicará la aceptación de los mismos.<br /><br />

            3.	DATOS PERSONALES<br /><br />

            Soluciones Técnicas del Metal S.L. recogerá exclusivamente aquellos datos personales adecuados, pertinentes y limitados a lo necesario en relación con los fines para los que son tratados, según correspondan en cada caso.<br /><br />

            El usuario garantiza la autenticidad de los datos comunicados y será el único responsable de cualesquiera manifestaciones falsas o inexactas.<br /><br />

            4.	FINES DEL TRATAMIENTO<br /><br />

            Los datos personales recibidos a través del Sitio Web se tratarán para las siguientes finalidades, según corresponda en cada caso:<br /><br />

            •	Atender las solicitudes, peticiones, consultas, dudas, quejas o reclamaciones que los usuarios dirijan a Soluciones Técnicas del Metal S.L.<br /><br />

            •	Responder a aquellas personas que se han puesto en contacto con Soluciones Técnicas del Metal S.L. con el interés potencial de devenir clientes, distribuidores o proveedores de Soluciones Técnicas del Metal S.L.<br /><br />

            •	Realizar la gestión comercial y administrativa en ocasión de la relación entre Soluciones Técnicas del Metal S.L. y sus clientes, que incluirá la emisión y gestión de ofertas, pedidos y documentación contractual, emisión de facturas y control de cobro de las mismas.<br /><br />

            •	Realizar la gestión comercial y administrativa en ocasión de la relación entre Soluciones Técnicas del Metal S.L. y sus proveedores, que incluirá la valoración y gestión de ofertas, pedidos y documentación contractual, recepción y pago de facturas y control de pago de las mismas.<br /><br />

            •	Mantenimiento del contacto profesional en ocasión de la relación entre Soluciones Técnicas del Metal S.L. y sus clientes y/o proveedores.<br /><br />

            •	Enviar comunicaciones relativas a novedades, promociones, concursos, sorteos, eventos y/o información corporativa de Soluciones Técnicas del Metal S.L. y sus productos y/o servicios.<br /><br />

            •	Gestión del cumplimiento de las obligaciones legalmente estipuladas.<br /><br />

            •	Prevención, detección y control de abusos y fraudes cometidos en el Sitio Web o a través del mismo.<br /><br />

            5.	LEGITIMACIÓN DEL TRATAMIENTO<br /><br />

            La base jurídica del tratamiento de los datos personales será el consentimiento del usuario, en caso de haber sido prestado en las siguientes finalidades:<br /><br />

            •	Atender las solicitudes, peticiones, consultas, dudas, quejas o reclamaciones que los usuarios dirijan a Soluciones Técnicas del Metal S.L.<br /><br />

            •	Enviar comunicaciones relativas a novedades, promociones, concursos, sorteos, eventos y/o información corporativa de Soluciones Técnicas del Metal S.L. y sus productos y/o servicios.<br /><br />

            El usuario tendrá derecho a retirar el consentimiento en cualquier momento, sin que ello afecte a la licitud del tratamiento previo a su retirada.<br /><br />

            La base jurídica del tratamiento de los datos personales será el cumplimiento de obligaciones pre-contractuales o contractuales, en caso de haber sido prestado en las siguientes finalidades:<br /><br />

            •	Responder a aquellas personas que se han puesto en contacto con Soluciones Técnicas del Metal S.L. con el interés potencial de devenir clientes, distribuidores o proveedores de Soluciones Técnicas del Metal S.L.<br /><br />

            •	Realizar la gestión comercial y administrativa en ocasión de la relación entre Soluciones Técnicas del Metal S.L. y sus clientes, que incluirá la emisión y gestión de ofertas, pedidos y documentación contractual y control de cobro de las facturas.<br /><br />

            •	Realizar la gestión comercial y administrativa en ocasión de la relación entre Soluciones Técnicas del Metal S.L. y sus proveedores, que incluirá la valoración y gestión de ofertas, pedidos y documentación contractual, recepción y pago de facturas y control de pago de las mismas.<br /><br />

            El tratamiento de los datos personales es necesario para poder suscribir y ejecutar el contrato entre Soluciones Técnicas del Metal S.L. y sus clientes y/o proveedores.<br /><br />

            La base jurídica del tratamiento de los datos personales será el cumplimiento de una obligación legal, en caso de haber sido prestado en las siguientes finalidades:<br /><br />

            •	Realizar la gestión administrativa en ocasión de la relación entre Soluciones Técnicas del Metal S.L. y sus clientes, que incluirá la emisión de facturas.<br /><br />

            •	Gestión del cumplimiento de las obligaciones legalmente estipuladas.<br /><br />

            El tratamiento de los datos personales según las referidas finalidades es necesario para que Soluciones Técnicas del Metal S.L. pueda cumplir con las obligaciones que le impone la legislación aplicable.<br /><br />

            Finalmente, la base jurídica del tratamiento de los datos personales será el interés legítimo del responsable del tratamiento, en caso de haber sido prestado en las siguientes finalidades:<br /><br />

            •	Mantenimiento del contacto profesional en ocasión de la relación entre Soluciones Técnicas del Metal S.L. y sus clientes y/o proveedores, según el interés legítimo reconocido por la ley.<br /><br />

            •	Prevención, detección y control de abusos y fraudes cometidos en el Sitio Web o a través del mismo, según el interés legítimo de mantener el Sitio Web como una página web segura.<br /><br />

            6.	DESTINATARIOS<br /><br />

            Soluciones Técnicas del Metal S.L. no venderá los datos personales objeto de sus tratamientos. Además, no realizará comunicaciones de datos personales a terceros, excepto en caso de obligación legal y a terceros contratados.<br /><br />

            Soluciones Técnicas del Metal S.L. puede precisar realizar transferencias internacionales de datos a proveedores ubicados en países que no disponen de normativa equivalente a la europea para el tratamiento de datos personales, dicha contratación se realizaría aplicando las garantías y salvaguardas necesarias para preservar la privacidad de los usuarios. Para más información, contactar con el responsable del tratamiento al correo electrónico lopd@stmseguridad.com.<br /><br />

            7.	PLAZOS DE CONSERVACIÓN.<br /><br />

            En caso de que la base jurídica del tratamiento consista en el consentimiento del usuario, los datos serán conservados mientras éste no se revoque.<br /><br />

            Los datos personales se conservarán durante todo el periodo de tiempo que pudiesen exigirse responsabilidades a Soluciones Técnicas del Metal S.L. según estipula la ley aplicable, procediendo, en su caso, al bloqueo de los datos hasta que haya transcurrido el plazo de prescripción correspondiente.<br /><br />

            8.	DERECHOS DE LOS USUARIOS<br /><br />

            Le informamos que en cualquier momento puede ejercer los derechos de acceso, rectificación, supresión, limitación, oposición y portabilidad respecto a sus datos de carácter personal previstos en la normativa en materia de protección de datos, enviando un escrito al domicilio postal en avenida del Castell de Barberà, 27, 08210 Barberá del Vallès o al correo electrónico lopd@stmseguridad.com.<br /><br />

            En concreto, puede ejercer los derechos que se desarrollan a continuación:<br /><br />

            -	Revocar los consentimientos otorgados.<br /><br />

            -	Obtener confirmación sobre si la empresa está tratando datos personales que les conciernen o no.<br /><br />

            -	Acceder a sus datos personales.<br /><br />

            -	Rectificar los datos inexactos o incompletos.<br /><br />

            -	Solicitar la supresión de sus datos cuando, entre otros motivos, los datos ya no sean necesarios para las finalidades por las cuales fueron recogidas.<br /><br />

            -	Ejercer la limitación del tratamiento de los datos cuando se cumpla alguna de las condiciones previstas en la normativa de protección de datos.<br /><br />

            -	En determinadas circunstancias y por motivos relacionados con su situación particular al tratamiento de sus datos, los interesados podrán oponerse al tratamiento de sus datos. La empresa dejará de tratar los datos, excepto por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.<br /><br />

            -	 Obtener intervención humana, a expresar su punto de vista y a impugnar las decisiones automatizadas adoptadas por parte de la empresa.<br /><br />

            -	Solicitar la portabilidad de sus datos.<br /><br />

            En caso de disconformidad en relación a la tramitación de sus datos puede presentar una reclamación ante la Agencia Española de Protección de Datos, organismo que ostenta la autoridad de control en la materia, ubicada en la calle Jorge Juan, número 6 (28001) Madrid (www.aepd.es).<br /><br />

        </div>
    )

}
