import React from "react";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Mobile from "../../../assets/uploads/productConan/iphone_web.png";

export default function Block2() {

    return (
        <div className="block2-product-conan px-md-5-34 px-lg-10-42">
            <Grid container className="grid-container">
                <Grid item className="grid-item">
                    <img src={Mobile} className="mobile-image" />
                </Grid>
                <Grid item className="grid-item">
                    <h2 className="mt-0">Seguridad combinada:</h2>
                    <Grid container className="text-container">
                        <Grid item>
                            <p className="mt-0"><span className="span-thin">STM</span><br /><span className="span-bold">Power</span><br />Interrumpe <br className="br-desktop" />el suministro <br className="br-desktop" />eléctrico.</p>
                        </Grid>
                        <Grid item>
                            <p className="mt-0 last-paragraph"><span className="span-thin">STM</span><br /><span className="span-bold">Block</span><br />Bloquea <br className="br-desktop" />el desagüe <br className="br-desktop" />del inodoro.</p>
                        </Grid>
                    </Grid>
                    <Link href="/contact" sx={{
                        textDecoration: "none"
                    }}>
                        <Button variant="contained" className="btn-contained-blue">Solicitar ficha técnica</Button>
                    </Link>
                </Grid>
            </Grid>
        </div>
    )

}