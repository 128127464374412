import React from "react";
import {Grid, Box, LinearProgress} from "@mui/material";

/**
 * Muestra una barra de progreso de carga
 *
 * ## PROPS
 * - **loading** *boolean* - Indica si se está cargando algo
 * - **marginTop** *number* - Margen superior
 */
export default function LoadingBar(props) {

    const {loading, marginTop} = props

    return (
        <>
            {loading && (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress style={{marginTop: marginTop}}/>
                </Box>
            )}
            {!loading && (
                <Box sx={{ width: '100%', height: 5 }} style={{marginTop: marginTop}}></Box>
            )}
        </>
    )
}
