import React, { useRef, useState } from "react";
import Block1 from "./Block1";
import Block2 from "./Block2";
import Block3 from "./Block3";
import Block4 from "./Block4";
import Block5 from "./Block5";
import BasicFooter from "../../../components/Layouts/User/BasicFooter";

export default function ProductTower() {
  
    return (
        <div className="products">
            <Block1 />
            <Block2 />
            <Block3 />
            <Block4 />
            <Block5 />
            <BasicFooter />
        </div>
    )
}
