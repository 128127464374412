import React from "react";
import Grid from '@mui/material/Grid';

export default function Block3() {

    return (
        <div className="block3-product-conan px-lg-10-42">
            <Grid container className="grid-container">
                <Grid item xs={12} className="grid-item px-md-5-34">
                    <h2 className="mt-0">Los okupas no tendrán opción.</h2>
                    <p className="mt-0"><span class="paragraph-thin">STM</span> <span className="paragraph-bold">Conan</span> se activa automáticamente cuando cualquier dispositivo STM detecta un intento de intrusión.</p>
                    <h3 className="margin-0">El sistema se mantiene operativo hasta que usted recupere el inmueble, cuando los componentes son retirados fácilmente.</h3>
                </Grid>
            </Grid>
        </div>
    )

}