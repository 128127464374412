import React, { useRef, useState } from "react";
import Carusel1 from "./Carusel1";
import Carusel2 from "./Carusel2";
import Carusel3 from "./Carusel3";
import Carusel4 from "./Carusel4";
import Carusel5 from "./Carusel5";
import Carusel6 from "./Carusel6";
import CaruselVideo from "./CaruselVideo";
import CarouselFooter from "../../../components/Layouts/User/CarouselFooter";
import BasicFooter from "../../../components/Layouts/User/BasicFooter";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// swiper bundle styles
import 'swiper/swiper-bundle.min.css'

// swiper core styles
import 'swiper/swiper.min.css'

// modules styles
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'

// import Swiper core and required modules
import SwiperCore, {Mousewheel,Pagination} from 'swiper';

// install Swiper modules
SwiperCore.use([Mousewheel,Pagination]);

export default function About() {
  
    return (
        <div className="about">
            <Carusel1 />
            <Carusel2 />
            <CaruselVideo />
            <Carusel5 />
            <Carusel6 />
            <BasicFooter />
        </div>
    )
}
