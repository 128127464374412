import * as React from 'react';
import {Grid, IconButton, Box} from "@mui/material";
import imgLogo from "../../../assets/uploads/Logo_STM.svg"
import MenuIcon from '@mui/icons-material/Menu';
import {useState} from "react";
import Menu from "./Menu";
import Link from '@mui/material/Link';
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import {LINK_FACEBOOK, LINK_INSTAGRAM, LINK_TWITTER} from "../../../utils/constants";
import {useHistory} from "react-router-dom";
import {RouteUser} from "../../../routes/RouteUser";

export default function Header() {
    const [open, setOpen] = useState(false)
    const history = useHistory()

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleClickLogo = () => {
        history.push(RouteUser.ROOT.path)
    }

    return (
        <header className="header">
            <div className="container header-no-product">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={10} lg={10} style={{padding: 0}}>
                        <p className="help"><strong>¿Podemos ayudarle?</strong> +34 935 752015  <span className="email"><Link href="mailto:info@stmseguridad.com" sx={{color: "inherit",textDecoration: "none"}}>info@stmseguridad.com</Link></span></p>
                    </Grid>
                    <Grid item md={2} lg={2} sx={{ display: { xs: 'none', md: 'inline', lg: 'inline' }, zIndex: "10" }} style={{padding: 0}}>
                        <Box display="flex" justifyContent="flex-end">
                            <a href={LINK_FACEBOOK} target="_blank"><FacebookIcon className="icon-social"/></a>
                            <a href={LINK_TWITTER} target="_blank"><TwitterIcon className="icon-social"/></a>
                            <a href={LINK_INSTAGRAM} target="_blank"><InstagramIcon className="icon-social"/></a>
                        </Box>
                    </Grid>
                    <Grid item xs={9} md={6} lg={6} className="margin-top-20px"  style={{padding: 0}}>
                        <img src={imgLogo} className="logo" alt="STM Logo" onClick={handleClickLogo}/>
                    </Grid>
                    <Grid item xs={3} md={6} lg={6} className="margin-top-20px" style={{padding: 0}}>
                        <Box display="flex" justifyContent="flex-end">
                            <IconButton className="menu-button" onClick={handleClickOpen}>
                                <MenuIcon className="menu-icon"/>
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>

                <Menu
                    handleClose={handleClose}
                    open={open}
                />
            </div>
        </header>


    )
}
