import React from "react";
import Button from "@mui/material/Button";
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';

export default function Carusel2() {

    return (
        <div className="carusel2 px-md-20px px-lg-150px py-md-60px element-view-height h-xs-auto pt-lg-124px">
            <Grid container sx={{
                height: "100%",
                alignItems: "center",
                alignContent: "center",
                maxWidth: "1140px"
            }}>
                <Grid item xs={12} sx={{
                    marginBottom: "0px"
                }}>
                    <h2>Somos especialistas en seguridad.</h2>
                    <p>Ofrecemos una solución integral y modular para proteger las viviendas temporalmente vacías.</p>
                </Grid>
                <Grid item xs={12}>
                    <Link href="/products" sx={{
                        textDecoration: "none"
                    }}>
                        <Button variant="outlined" className="btn-four-blue btn-carusel2">Vea nuestros productos</Button>
                    </Link>
                </Grid>
            </Grid>
        </div>
    )
}