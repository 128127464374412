import React from "react";
import {Divider, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {People} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import {RouteAdmin} from "../../../routes/RouteAdmin";
import {Link} from "react-router-dom";
import ContactsIcon from '@mui/icons-material/Contacts';

export default function menuPrimary(props) {
    const {menu} = props
    const {t} = useTranslation()

    return (
        <div style={{marginTop: 20}}>
            <MenuLink type="contact" menu={menu} href={RouteAdmin.CONTACT_MANAGEMENT.path} name="Contactos" icon={<ContactsIcon />}/>

            {/*<Divider style={{marginBottom: 10, marginTop: 10}}/>*/}

            {/*<MenuLink type="users" menu={menu} href={RouteAdmin.USER_MANAGEMENT.path} name="Usuarios" icon={<People />}/>*/}
            {/*<MenuLink type="media" menu={menu} href={RouteAdmin.MEDIA_MANAGEMENT.path} name="Media" icon={<PermMediaIcon />}/>*/}
        </div>
    )
}

function MenuLink(props) {
    const {href, name, menu, type, icon} = props

    let isActive = false
    if (menu === type) {
        isActive = true
    }

    return (
        <>
            <div  style={{ marginRight: '25px' }}>
            <ListItem
                component={Link}
                to={href}
                selected={isActive}
                sx={{ borderRadius: '8px', marginLeft: '10px', color: '#000' }}
            >
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={name} />
            </ListItem>
            </div>
        </>
    )
}
