import React from "react";
import Grid from '@mui/material/Grid';

export default function Carusel6() {

    return (
        <div className="carousel6 px-md-20px px-lg-150px py-md-60px element-view-height h-xs-auto pt-lg-124px">
            <Grid container sx={{
                height: "100%",
                alignItems: "center",
                alignContent: "center",
                maxWidth: "1140px"
            }}>
                <Grid item xs={12} sx={{
                    marginBottom: "67px"
                }}>
                    <h2>Los actores más relevantes de la <span>Seguridad</span> y el <span>Real State <span className="text-decoration-underline">ya trabajan con nosotros</span>.</span></h2>
                    <p><span>STM Security & Intelligent Control</span> trabaja protegiendo los activos de las principales entidades financieras e instituciones públicas del Estado. Nuestras soluciones de seguridad para las viviendas deshabitadas son referencia para las grandes empresas y también pretendemos serlo para el cliente particular.</p>
                    <Grid container className="marcas-container">
                        <Grid item className="marcas-item">
                            <h3>Aliseda</h3>
                        </Grid>
                        <Grid item className="marcas-item">
                            <h3>Anticipa</h3>
                        </Grid>
                        <Grid item className="marcas-item">
                            <h3>Haya</h3>
                        </Grid>
                        <Grid item className="marcas-item">
                            <h3>Sareb</h3>
                        </Grid>
                        <Grid item className="marcas-item">
                            <h3>Securitas</h3>
                        </Grid>
                        <Grid item className="marcas-item">
                            <h3>La Caixa</h3>
                        </Grid>
                        <Grid item className="marcas-item-otros">
                            <h3>Entre otros</h3>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}