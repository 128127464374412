import React from "react";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import paok from "../../../assets/uploads/productPaokElectronica/Group_5_Copy_2.png";

export default function Block1() {

    return (
        <div className="block1-product-paok-elec px-md-5-34 px-lg-10-42 margin-top-navbar">
            <Grid container className="grid-container" sx={{
                height: "100%"
            }}>
                <Grid item xs={6} className="grid-item" sx={{
                    height: "max-content"
                }}>
                    <h2 className="mt-0 title-h2">Electrónica</h2>
                    <Grid container xs={6} className="title-grid" sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                        maxWidth: "100%"
                    }}>
                        <Grid item sx={{
                            width: "max-content"
                        }}>
                            <h3 className="margin-0">STM</h3>
                        </Grid>
                        <Grid item sx={{
                            width: "max-content"
                        }}>
                            <h1 className="mt-0">Anti-</h1>
                        </Grid>
                    </Grid>
                    <h1 className="mt-0 last-title">Okupa</h1>
                    <h2 className="mt-0 h2-text">La puerta antiokupa <br />con cerradura motorizada <br />de alta seguridad</h2>
                    <p className="mt-0">especialmente diseñada para las puertas STM <span>Antiokupa</span> y para su tranquilidad.</p>
                    <Link href="/contact" sx={{
                        textDecoration: "none"
                    }}>
                        <Button variant="outlined" className="btn-four-blue">Infórmese</Button>
                    </Link>
                </Grid>
                <Grid item xs={6} className="grid-item" sx={{
                    height: "max-content"
                }}>
                    <img src={paok} />
                </Grid>
            </Grid>
        </div>
    )

}