import React from "react";
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import Link from '@mui/material/Link';
import vector1 from "../../../assets/uploads/productDoor/vector_1.svg"
import vector2 from "../../../assets/uploads/productDoor/vector_2.svg"
import vector3 from "../../../assets/uploads/productDoor/vector_3.svg"
import vector4 from "../../../assets/uploads/productDoor/vector_4.svg"

export default function Block3() {

    return (
        <div className="block3-product-door px-lg-10-42">
            <Grid container className="grid-container">
                <Grid item xs={12} className="grid-item px-md-5-34">
                    <h2 className="mt-0">La puerta a su tranquilidad.</h2>
                    <p className="mt-0"><span class="paragraph-thin">STM</span> <span className="paragraph-bold">Door</span> es una puerta acorazada de alta seguridad con control remoto que mantiene tus inmuebles siempre bajo control. Pero no sólo eso.</p>
                </Grid>
                <Grid container className="list-container px-md-5-34">
                    <Grid item xs={12} className="vector-item vector-item-1">
                        <div className="span-container span-margin-bottom">
                            <span className="vector-text">Cerradura motorizada de 6 puntos.</span>
                        </div>
                        <img src={vector1} className="img-vector img-vector-1" />
                    </Grid>
                    <Grid item xs={12} className="vector-item vector-item-2">
                        <div className="span-container span-margin-bottom">
                            <span className="vector-text">Cerradura mecánica <br />de grado 6.</span>
                        </div>
                        <img src={vector2} className="img-vector img-vector-2" />
                    </Grid>
                    <Grid item xs={12} className="vector-item vector-item-3">
                        <div className="third-vector-div">
                            <div className="span-container span-margin-bottom-third">
                                <span className="vector-text">Aviso de intento de intrusión <br />al Cloud mediante conexión GPRS.</span>
                            </div>
                            <img src={vector3} className="img-vector img-vector-3" />
                        </div>
                    </Grid>
                    <Grid item xs={12} className="vector-item vector-item-4">
                        <div className="last-vector-div">
                            <div className="span-container span-margin-bottom span-margin-bottom-last">
                                <span className="vector-text">Revestimiento de madera.</span>
                            </div>
                            <img src={vector4} className="img-vector img-vector-4" />
                        </div>
                    </Grid>
                    <Link href="/contact" sx={{
                        textDecoration: "none"
                    }}>
                        <Button variant="outlined" className="btn-four-blue">Infórmese</Button>
                    </Link>
                </Grid>
            </Grid>
        </div>
    )

}