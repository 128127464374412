import React from "react";
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import Link from '@mui/material/Link';
import vector1 from "../../../assets/uploads/productPaokElectronica/vector_1.svg"
import vector2 from "../../../assets/uploads/productPaokElectronica/vector_2.svg"
import vector3 from "../../../assets/uploads/productPaokElectronica/vector_3.svg"
import vector4 from "../../../assets/uploads/productPaokElectronica/vector_4.svg"

export default function Block3() {

    return (
        <div className="block3-product-paok-elec px-md-5-34 px-lg-10-42">
            <Grid container className="grid-container">
                <Grid item xs={12} className="grid-item">
                    <h2 className="mt-0">Nada se escapa a su control.</h2>
                    <p className="mt-0"><span class="paragraph-thin">STM</span> <span className="paragraph-bold">AntiOkupa</span> electrónica detecta las vibraciones,
                    los golpes y cualquier tipo de intrusión
                    para garantizar la máxima seguridad.</p>
                </Grid>
                <Grid item xs={12} className="vector-item vector-item-1">
                    <div className="span-container span-margin-bottom">
                        <span className="vector-text">Sistema de comunicación habla-escucha en caso de salto de alarma.</span>
                    </div>
                    <img src={vector1} className="img-vector img-vector-1" />
                </Grid>
                <Grid item xs={12} className="vector-item vector-item-2">
                    <div className="span-container span-margin-bottom">
                        <span className="vector-text">Tamper interno de seguridad anti-intrusión.</span>
                    </div>
                    <img src={vector2} className="img-vector img-vector-2" />
                </Grid>
                <Grid item xs={12} className="vector-item vector-item-3">
                    <div className="third-vector-div">
                        <div className="span-container span-margin-bottom-third">
                            <span className="vector-text">Control <br />de estado <br />de baterías <br />y puerta.</span>
                        </div>
                        <img src={vector3} className="img-vector img-vector-3" />
                    </div>
                </Grid>
                <Grid item xs={12} className="vector-item vector-item-4">
                    <div className="last-vector-div">
                        <div className="span-container span-margin-bottom span-margin-bottom-last">
                            <span className="vector-text">Reporte diario <br />al cloud.</span>
                        </div>
                        <img src={vector4} className="img-vector img-vector-4" />
                    </div>
                </Grid>
                <Link href="/contact" sx={{
                    textDecoration: "none"
                }}>
                    <Button variant="outlined" className="btn-four-blue">Infórmese</Button>
                </Link>
            </Grid>
        </div>
    )

}