import React from "react";
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import Link from '@mui/material/Link';
import vector from "../../../assets/uploads/productSentinel/Fill_1.png";

export default function Block3() {

    return (
        <div className="block3-product-sentinel px-lg-10-42">
            <Grid container className="grid-container">
                <Grid item xs={12} className="grid-item px-md-5-34">
                    <h2 className="mt-0">Alta seguridad y bajo coste, sin llamar la atención.</h2>
                    <p className="mt-0"><span class="paragraph-thin">STM</span> <span className="paragraph-bold">Sentinel</span> protege sus activos desde el interior de forma remota. El pequeño dispositivo de alta seguridad envía un aviso a nuestros profesionales en caso de que se produzca una entrada desconocida en el inmueble y nosotros activamos los protocolos necesarios.</p>
                </Grid>
                <Grid container className="list-container px-md-5-34">
                    <Grid item xs={12} className="vector-item vector-item-1">
                        <div className="vector-image-container">
                            <img src={vector} />
                        </div>
                        <div className="span-container span-margin-bottom">
                            <span className="vector-text">Rápida instalación y fácil montaje</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} className="vector-item vector-item-2">
                        <div className="vector-image-container">
                            <img src={vector} />
                        </div>
                        <div className="span-container span-margin-bottom">
                            <span className="vector-text">Control de estado sin llamar la atención</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} className="vector-item vector-item-3">
                        <div className="vector-image-container">
                            <img src={vector} />
                        </div>
                        <div className="span-container span-margin-bottom-third">
                            <span className="vector-text">2 años de autonomía</span>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )

}