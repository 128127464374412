import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import imgLogo from "../../../assets/uploads/Logo_STM.svg"
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import Button from "@mui/material/Button";
import Footer from "./Footer";

export default function BasicFooter() {


    return (
        <footer className="footer">
            <Footer/>
        </footer>

    );
}