import React from "react";
import Grid from '@mui/material/Grid';
import imgEco from "../../../assets/uploads/eco.png";

export default function Carusel5() {

    return (
        <div className="carousel5-about px-md-20px px-lg-150px py-60px h-xs-auto">
            <Grid container sx={{
                display: "flex",
                alignContent: "center"
            }}>
                <Grid item xs={12}>
                    <h2>Lo mas seguro es adaptarse, lo más inteligente es evolucionar</h2>
                </Grid>
                <Grid item xs={12} className="carousel5-img-container">
                    <img src={imgEco} alet="" className="" alt=""/>
                </Grid>
            </Grid>
        </div>
    )
}