import React from "react";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import imgGeoLocation from "../../../assets/uploads/noun-geo-location-2955123.svg"
import imgFacebook from "../../../assets/uploads/Facebook.png"
import imgTwitter from "../../../assets/uploads/Twitter.png"
import imgInstagram from "../../../assets/uploads/Instagram.png"

export default function Block1() {

    return (
        <div className="block1 px-md-20px px-lg-150px">
            <Grid container>
                <Grid item xs={12}>
                    <h2 className="block1-title margin-0">Contacte con nosotros</h2>
                </Grid>
                <Grid container sx={{
                    height: "100%",
                    alignItems: "center",
                    alignContent: "center"
                }} className="text-row">
                    <Grid item xs={12} className="text-block">
                        <p><span>Sede central</span><br/>Carretera de Santiga 86-90<br/>08291 Ripollet<br/>Barcelona</p>
                        <p>+34 914 267 210<br/>+34 935 752 015<br/><span>info@stmseguridad.com</span></p>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="outlined" className="btn-primary-white btn-block1 mb-md-40px">Cómo llegar</Button>
                </Grid>
                <Grid item xs={12} className="follow-block">
                    <Grid item xs={12}>
                        <h3 className="block1-title margin-0">Síguenos</h3>
                    </Grid>
                    <Grid item xs={12} className="follow-images">
                        <a href="https://www.instagram.com/stm_seguridad/"><img src={imgFacebook} alt="imgAlmacenes" className="img-facebook"/></a>
                        <a href="https://twitter.com/STMseguridad"><img src={imgTwitter} alt="imgAlmacenes" className="img-twitter"/></a>
                        <a href="https://www.facebook.com/STMSeguridadESP/"><img src={imgInstagram} alt="imgAlmacenes" className="img-instagram"/></a>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )

}
