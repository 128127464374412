import React from "react";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Mobile from "../../../assets/uploads/productMot/iphone_web.png";

export default function Block2() {

    return (
        <div className="block2-product-mot px-md-5-34 px-lg-10-42">
            <Grid container className="grid-container">
                <Grid item className="grid-item">
                    <img src={Mobile} className="mobile-image desktop-display" />
                </Grid>
                <Grid item className="grid-item">
                    <h2 className="mt-0 desktop"><span>STM</span> Mot</h2>
                    <p className="mt-0 desktop">es la forma más inteligente de controlar las zonas comunes.</p>
                    <p className="mt-0 mobile"><span className="span-thin">STM</span> <span className="span-bold">Mot</span> es la forma más inteligente de controlar las zonas comunes.</p>
                    <Link href="/contact" sx={{
                        textDecoration: "none"
                    }}>
                        <Button variant="contained" className="btn-contained-blue">Solicitar ficha técnica</Button>
                    </Link>
                </Grid>
            </Grid>
        </div>
    )

}