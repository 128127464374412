import React from "react";
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import Link from '@mui/material/Link';
import vector1 from "../../../assets/uploads/productReja/vector_1.svg"
import vector2 from "../../../assets/uploads/productReja/vector_2.svg"
import vector3 from "../../../assets/uploads/productReja/vector_3.svg"
import vector4 from "../../../assets/uploads/productReja/vector_4.svg"

export default function Block2() {

    return (
        <div className="block2-product-reja px-lg-10-42">
            <Grid container className="grid-container">
                <Grid item xs={12} className="grid-item px-md-5-34">
                    <h2 className="mt-0">Sin obras, sin daños y sin demoras.</h2>
                    <p className="mt-0"><span class="paragraph-thin">STM</span> <span className="paragraph-bold">Reja</span> se compone de una estructura de módulos que pueden ser cortados y empalmados con facilidad para garantizar una rápida instalación y una retirada sin daños.</p>
                </Grid>
                <Grid container className="list-container px-md-5-34">
                    <Grid item xs={12} className="vector-item vector-item-1">
                        <div className="span-container span-margin-bottom">
                            <span className="vector-text">Reja telescópica acoplable</span>
                        </div>
                        <img src={vector1} className="img-vector img-vector-1" />
                    </Grid>
                    <Grid item xs={12} className="vector-item vector-item-2">
                        <div className="span-container span-margin-bottom">
                            <span className="vector-text">Módulos de 140x90cm</span>
                        </div>
                        <img src={vector2} className="img-vector img-vector-2" />
                    </Grid>
                    <Grid item xs={12} className="vector-item vector-item-3">
                        <div className="third-vector-div">
                            <div className="span-container span-margin-bottom">
                                <span className="vector-text">Material de alta resistencia</span>
                            </div>
                            <img src={vector3} className="img-vector img-vector-3" />
                        </div>
                    </Grid>
                    <Grid item xs={12} className="vector-item vector-item-4">
                        <div className="last-vector-div">
                            <div className="span-container span-margin-bottom span-margin-bottom-last">
                                <span className="vector-text">Acabado Zincado inoxidable</span>
                            </div>
                            <img src={vector4} className="img-vector img-vector-4" />
                        </div>
                    </Grid>
                    <Link href="/contact" sx={{
                        textDecoration: "none"
                    }}>
                        <Button variant="outlined" className="btn-four-blue">Contáctenos</Button>
                    </Link>
                </Grid>
            </Grid>
        </div>
    )

}