import React from "react";
import {TextField, useTheme, useMediaQuery} from "@mui/material";

export default function InputText(props) {

    const {label, name, value, onChange, required} = props

    let placeholder = label

    if (useMediaQuery(useTheme().breakpoints.up("md"))) {
        placeholder = ""
    }

    return (
        <>
            {!placeholder && <p>{label}</p>}
            <TextField
                type="text"
                variant="outlined"
                className="input-text"
                size="small"
                fullWidth
                required={required}
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
            />
        </>
    )
}