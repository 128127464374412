import React from "react";
import Grid from '@mui/material/Grid';
import rejaEsquema from "../../../assets/uploads/productReja/Reja_esquema.png"

export default function Block3() {

    return (
        <div className="block3-product-reja px-md-5-34 px-lg-10-42">
            <Grid container className="grid-container">
                <Grid item xs={12} md={6} className="grid-item">
                    <img src={rejaEsquema} />
                </Grid>
                <Grid item xs={12} md={6} className="grid-item">
                    <h2 className="mt-0"><span className="paragraph-thin">STM</span> Reja, la seguridad se multiplica</h2>
                    <p className="margin-0">Combine la <span className="paragraph-underline">STM <span className="paragraph-bold">Antiokupa</span></span> con los productos <span className="paragraph-underline">STM <span className="paragraph-bold">Conan</span></span>, <span className="paragraph-underline">STM <span className="paragraph-bold">Sentinel</span></span>, <span className="paragraph-underline">STM <span className="paragraph-bold">Gravity</span></span> y <span className="paragraph-underline">STM <span className="paragraph-bold">Alarma</span></span> para lograr la protección más completa ante cualquier intento de intrusión.</p>
                </Grid>
            </Grid>
        </div>
    )

}