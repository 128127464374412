import React from "react";
import Grid from '@mui/material/Grid';
import imgAlmacenes from "../../../assets/uploads/Almacenes.png"
import imgCall from "../../../assets/uploads/Call_centre.png"
import imgFlota from "../../../assets/uploads/Flota.png"
import imgInstaladores from "../../../assets/uploads/Instaladores.png"
import Button from "@mui/material/Button";
import Modal from "./Modal"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// swiper bundle styles
import 'swiper/swiper-bundle.min.css'

// swiper core styles
import 'swiper/swiper.min.css'

// modules styles
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'

// import Swiper core and required modules
import SwiperCore, {Mousewheel,Pagination} from 'swiper';

export default function Carusel4() {

    return (
        <div className="carousel4 px-md-20px px-lg-150px py-md-60px element-view-height h-xs-auto pt-lg-124px">
            <Grid container sx={{
                height: "100%",
                alignItems: "center",
                alignContent: "center"
            }} className="carousel4-grid-container">
                <Grid item xs={12} sx={{
                    marginBottom: "6vh"
                }}>
                    <p className="margin-0">Más de <span>8 millones de accesos</span> y <span>12.500 activos inmobiliarios</span> seguros en todo momento</p>
                </Grid>
                <Grid item xs={12} className="carousel4-grid" sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    justifyContent: "space-between"
                }}>
                    <Grid container className="carousel4-card" md={4} xl={3} sx={{
                        width: "max-content",
                        mb: "3vh"
                    }}>
                        <Grid item xs={12} sx={{
                            marginBottom: "25px"
                        }}>
                            <img src={imgCall} alet="imgAlmacenes" className="" alt=""/>
                        </Grid>
                        <Grid item xs={12} sx={{
                            width: "max-content"
                        }}>
                            <h3 className="margin-0">1</h3>
                            <h3 className="mt-0 icons-titles">Call Center</h3>
                            <p className="margin-0">con servicio<br />24/7</p>
                        </Grid>
                    </Grid>
                    <Grid container className="carousel4-card" md={4} xl={3} sx={{
                        width: "max-content",
                        mb: "3vh"
                    }}>
                        <Grid item xs={12} sx={{
                            marginBottom: "25px"
                        }}>
                            <img src={imgAlmacenes} alet="imgAlmacenes" className="" alt=""/>
                        </Grid>
                        <Grid item xs={12} sx={{
                            width: "max-content"
                        }}>
                            <h3 className="margin-0">46</h3>
                            <h3 className="mt-0 icons-titles">almacenes</h3>
                            <p className="margin-0">en territorio<br />nacional</p>
                        </Grid>
                    </Grid>
                    <Grid container className="carousel4-card" md={4} xl={3} sx={{
                        width: "max-content",
                        mb: "3vh"
                    }}>
                        <Grid item xs={12} sx={{
                            marginBottom: "25px"
                        }}>
                            <img src={imgFlota} alet="imgAlmacenes" className="" alt=""/>
                        </Grid>
                        <Grid item xs={12} sx={{
                            width: "max-content"
                        }}>
                            <h3 className="margin-0">Flota</h3>
                            <h3 className="mt-0 icons-titles">propia</h3>
                            <p className="margin-0">con 50<br />vehículos</p>
                        </Grid>
                    </Grid>
                    <Grid container className="carousel4-card" md={4} xl={3} sx={{
                        width: "max-content",
                        mb: "3vh"
                    }}>
                        <Grid item xs={12} sx={{
                            marginBottom: "25px"
                        }}>
                            <img src={imgInstaladores} alet="imgAlmacenes" className="" alt=""/>
                        </Grid>
                        <Grid item xs={12} sx={{
                            width: "max-content"
                        }}>
                            <h3 className="margin-0">200</h3>
                            <h3 className="mt-0 icons-titles">instaladores</h3>
                            <p className="margin-0">a su<br />servicio</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="carousel4-list" sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}>
                    <Grid container sx={{
                        width: "max-content",
                        mb: "60px"
                    }}>
                        <Grid item xs={12} sx={{
                            textAlign: "center"
                        }}>
                            <img src={imgCall} alet="imgAlmacenes" className="" alt=""/>
                        </Grid>
                        <Grid item xs={12} sx={{
                            width: "max-content"
                        }}>
                            <h3 className="margin-0">1</h3>
                            <h3 className="margin-0">Call Center</h3>
                            <p className="margin-0">con servicio 24/7</p>
                        </Grid>
                    </Grid>
                    <Grid container sx={{
                        width: "max-content",
                        mb: "60px"
                    }}>
                        <Grid item xs={12} sx={{
                            textAlign: "center"
                        }}>
                            <img src={imgAlmacenes} alet="imgAlmacenes" className="" alt=""/>
                        </Grid>
                        <Grid item xs={12} sx={{
                            width: "max-content"
                        }}>
                            <h3 className="margin-0">46</h3>
                            <h3 className="margin-0">almacenes</h3>
                            <p className="margin-0">en territorio nacional</p>
                        </Grid>
                    </Grid>
                    <Grid container sx={{
                        width: "max-content",
                        mb: "60px"
                    }}>
                        <Grid item xs={12} sx={{
                            textAlign: "center"
                        }}>
                            <img src={imgFlota} alet="imgAlmacenes" className="" alt=""/>
                        </Grid>
                        <Grid item xs={12} sx={{
                            width: "max-content"
                        }}>
                            <h3 className="margin-0">Flota</h3>
                            <h3 className="margin-0">propia</h3>
                            <p className="margin-0">con 50 vehículos</p>
                        </Grid>
                    </Grid>
                    <Grid container sx={{
                        width: "max-content"
                    }}>
                        <Grid item xs={12} sx={{
                            textAlign: "center"
                        }}>
                            <img src={imgInstaladores} alet="imgAlmacenes" className="" alt=""/>
                        </Grid>
                        <Grid item xs={12} sx={{
                            width: "max-content"
                        }}>
                            <h3 className="margin-0">200</h3>
                            <h3 className="margin-0">instaladores</h3>
                            <p className="margin-0">a su servicio</p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: "7vh"
                }}>
                    <Modal />
                </Grid>
            </Grid>
        </div>
    )
}