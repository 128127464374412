import React from "react";
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import Link from '@mui/material/Link';
import vector1 from "../../../assets/uploads/productMot/vector_1.svg"
import vector2 from "../../../assets/uploads/productMot/vector_2.svg"
import vector3 from "../../../assets/uploads/productMot/vector_3.svg"
import vector4 from "../../../assets/uploads/productMot/mot_pieza.svg"

export default function Block3() {

    return (
        <div className="block3-product-mot px-lg-10-42">
            <Grid container className="grid-container">
                <Grid item xs={12} className="grid-item px-md-5-34">
                    <h2 className="mt-0">Acceda a la última tecnología de control de accesos.</h2>
                    <p className="mt-0"><span class="paragraph-thin">STM</span> <span className="paragraph-bold">Mot</span> se instala en su interfono sin alterar la instalación comunitaria y se conecta mediante Bluetooth o GPRS para la apertura mediante la app.</p>
                </Grid>
                <Grid container className="list-container px-md-5-34">
                    <Grid item xs={12} className="vector-item-mot-pieza">
                        <img src={vector4} className="mot-pieza" />
                    </Grid>
                    <Grid item xs={12} className="vector-item vector-item-1">
                        <div className="span-container span-margin-bottom">
                            <span className="vector-text">Todo tipo de accesos:</span>
                        </div>
                        <img src={vector1} className="img-vector img-vector-1" />
                    </Grid>
                    <Grid item xs={12} className="vector-item vector-item-2">
                        <div className="span-container span-margin-bottom">
                            <span className="vector-text">Conexión vía BLE o GPRS de 1 o 2 pulsadores.</span>
                        </div>
                        <img src={vector2} className="img-vector img-vector-2" />
                    </Grid>
                    <Grid item xs={12} className="vector-item vector-item-3">
                        <div className="span-container span-margin-bottom-last">
                            <span className="vector-text">Fácil manejo.</span>
                        </div>
                        <img src={vector3} className="img-vector img-vector-3" />
                    </Grid>
                    <Link href="/contact" sx={{
                        textDecoration: "none"
                    }}>
                        <Button variant="outlined" className="btn-four-blue">Infórmese</Button>
                    </Link>
                </Grid>
            </Grid>
        </div>
    )

}