import React from "react";
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import Link from '@mui/material/Link';
import vector1 from "../../../assets/uploads/productGravity/vector_1.svg"
import vector2 from "../../../assets/uploads/productGravity/vector_2.svg"
import vector3 from "../../../assets/uploads/productGravity/vector_3.svg"
import vector4 from "../../../assets/uploads/productGravity/vector_4.svg"

export default function Block3() {

    return (
        <div className="block3-product-gravity px-md-5-34 px-lg-10-42">
            <Grid container className="grid-container">
                <Grid item xs={12} className="grid-item">
                    <h2 className="mt-0">Una cerradura nunca vista.</h2>
                    <p className="mt-0"><span class="paragraph-thin">STM</span> <span className="paragraph-bold">Gravity</span> se instala 
                    en el interior del inmueble 
                    sin causar ningún daño en la puerta 
                    haciendo que sea imperceptible 
                    desde el exterior.</p>
                </Grid>
                <Grid container className="list-container">
                    <Grid item xs={12} className="vector-item vector-item-1">
                        <div className="span-container span-margin-bottom">
                            <span className="vector-text">Bloqueo de alta seguridad.</span>
                        </div>
                        <img src={vector1} className="img-vector img-vector-1" />
                    </Grid>
                    <Grid item xs={12} className="vector-item vector-item-2">
                        <div className="span-container span-margin-bottom">
                            <span className="vector-text">Pomo de apertura manual con función antipánico.</span>
                        </div>
                        <img src={vector2} className="img-vector img-vector-2" />
                    </Grid>
                    <Grid item xs={12} className="vector-item vector-item-3">
                        <img src={vector3} className="img-vector img-vector-3 img-vector-desktop" />
                        <div className="span-container span-margin-bottom-third">
                            <span className="vector-text">Detección <br />de vibraciones e intrusión.</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} className="vector-item vector-item-4">
                        <img src={vector4} className="img-vector img-vector-4" />
                        <div className="span-container span-margin-bottom">
                            <span className="vector-text">Aviso de intento de intrusión <br />al Cloud mediante conexión GPRS.</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} className="vector-item vector-item-3 vector-item-mobile">
                        <img src={vector3} className="img-vector img-vector-3" />
                        <div className="span-container span-margin-bottom span-margin-bottom-last">
                            <span className="vector-text">Detección <br />de vibraciones e intrusión.</span>
                        </div>
                    </Grid>
                    <Link href="/contact" sx={{
                        textDecoration: "none"
                    }}>
                        <Button variant="outlined" className="btn-four-blue">Infórmese</Button>
                    </Link>
                </Grid>
            </Grid>
        </div>
    )

}