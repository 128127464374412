import React from "react";

export default function Block1() {

    return (
        <div className="condiciones-compra px-md-5-34 px-lg-10-42 margin-top-navbar">
La utilización de los servicios o la contratación de productos en www.stmseguridad.com suponen la aceptación de las siguientes condiciones generales:<br /><br />

1.- Aceptación y disponibilidad de las Condiciones Generales de Compra<br /><br />

Mediante la aceptación del presente contrato, usted declara: A) Que es una persona mayor de edad y con capacidad para contratar, b) Que ha leído y acepta las presentes condiciones generales.<br /><br />

Estas condiciones generales de venta (en adelante, “las Condiciones Generales”), regulan la relación jurídica que emana de los procesos de contratación realizados entre los usuarios-clientes (en adelante, “Usuario”) de la página Web de comercio electrónico ubicada en la url https://www.stmseguridad.com propiedad de Soluciones Técnicas del metal S.L. Los Usuarios aceptan las Condiciones Generales desde el instante que adquieran cualquier producto. Este documento puede ser impreso y almacenado por los Clientes, por su parte, Soluciones Técnicas del metal S.L, ante cualquier modificación de las presentes Condiciones Generales respetará siempre las que el usuario haya aceptado en el momento de realizar la compra, por lo que ponemos a disposición de estos la dirección de e-mail info@stmseguridad.com, para que puedan plantear cualquier duda acerca de las Condiciones Generales o solicitarnos una copia de las mismas en caso de pérdida.<br /><br />

2.- Normas aplicables<br /><br />

Las presentes Condiciones Generales, están sujetas a lo dispuesto a la Ley 7/1998, de 13 de abril, sobre Condiciones Generales de Contratación, al Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios, al Real Decreto 1906/1999, de 17 de diciembre de 1999, por el que se regula la Contratación Telefónica o Electrónica con condiciones generales, la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal, la Ley 7/1996, de 15 de enero de Ordenación del Comercio Minorista, reformada por la Ley 47/2002, de 19 de diciembre, y a la Ley 34/2002 de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico.<br /><br />

3.- Modificación de las Condiciones Generales<br /><br />

Soluciones Técnicas del metal S.L., podrá modificar las Condiciones Generales notificándolo a los Clientes con antelación suficiente, con el fin de mejorar los servicios y productos ofrecidos a través de www.stmseguridad.com. Mediante la modificación de las Condiciones Generales expuestas en la página Web de www.stmseguridad.com, se entenderá por cumplido dicho deber de notificación.<br /><br />

En todo caso, antes de utilizar los servicios o contratar productos, se podrán consultar las Condiciones Generales.<br /><br />

4.- Descripción de los productos y servicios<br /><br />

La utilización del sitio web www.stmseguridad.com, no necesita de registro del USUARIO para que este pueda adquirir los productos y/o servicios que se ofrecen en dicho sitio web. Sí implica la previa aceptación de nuestra ‘política de privacidad’ y de las presentes ‘condiciones generales de compra’ que rigen el servicio.<br /><br />

Visite la tienda online donde se encuentran los productos disponibles, accediendo al apartado VENTA O ALQUILER del menú superior de la web.<br /><br />

En la página de producto de la web, Soluciones Técnicas del metal S.L intenta plasmar, con la mayor precisión posible, las características del producto, tanto en la fotografía como en el texto que la acompaña. Sin embargo, hay que tener en cuenta que, la apreciación del acabado vinílico de los producto online puede diferir ligeramente de la apreciación física.En dicha página, seleccione el color en que quiere adquirir el producto, así como el resto de opciones que se le muestran en pantalla. Tras seleccionar las opciones deseadas, la web le mostrará el coste de su elección incluyendo los costes de envío. Si desea comprarlo clique el botón “COMPRAR”. Esto le llevará a una página con un formulario completo en el que recogeremos sus datos tanto de facturación como de entrega, al efecto de poder entregar su pedido. Al final de dicha página de formulario encontrará un botón de pago mediante pasarela segura.<br /><br />

Ámbito territorial del servicio:<br /><br />

Nuestra tienda online únicamente realiza entregas en territorio español de la península ibérica. Los pedidos de usuarios de las Islas Canarias, Illes Balears, Ceuta y Melilla y del extranjero deberán realizarse por correo electrónico (info@stmseguridad.com) mediante el botón “Solicitar presupuesto” presente en la página de VENTA O ALQUILER, indicando el pedido y sus datos para que le informemos sobre los costes de transporte y plazo de entrega.<br /><br />

Forma de envío y plazos de entrega:<br /><br />

El pedido será entregado a través de TNT, SEITRANS, o Mailboxes., a la dirección de envío indicada en el momento de la compra, que puede ser diferente a la dirección de facturación. Si en el primer intento de entrega en la dirección de envío no se encuentra nadie, TNT, SEITRANS, o Mailboxes., lo intentarán al segundo día laborable, o bien contactarán con usted para acordar la entrega.<br /><br />

La entrega se realizará a pie de calle. En este sentido, ponga especial atención en la dirección de entrega.El plazo de entrega del pedido es de máximo 5 días laborables a partir de la fecha de validación del pedido.<br /><br />

Costes de envío:<br /><br />

el precio del transporte es de 45€, por puerta y envío.<br /><br />

Precios<br /><br />

Los precios de los productos que se encuentran a la venta en nuestra tienda de comercio electrónico no incluyen el Impuesto de Valor Añadido (I.V.A), cuyo montante aparece desglosado durante el proceso de compra.<br /><br />

Modalidades de compra<br /><br />

Puede adquirir los productos de Soluciones Técnicas del metal S.L, en dos modalidades distintas;<br /><br />

– COMPRA: Esto le hace propietario de la puerta y le da derecho de uso de modo indefinido de la misma.<br /><br />

– ALQUILER: Esta opción crea un cargo recurrente a su cuenta mes a mes, lo que le da derecho al uso de la puerta, hasta el momento en que nos notifique su deseo de darse de baja de dicho servicio. Debe dar de baja el servicio con un mes de antelación comunicándolo al correo electrónico info@stmseguridad.com. Nos pondremos en contacto con usted por correo electrónico o por teléfono para concretar la recogida de la puerta.<br /><br />

La opción de alquiler implica una permanencia mínima de 1 mes.<br /><br />

Opciones de venta<br />
Las puertas están disponibles en 2 medidas. 840×2100 cm y 980×2100 cm. El precio en ambos casos es de 645 € +IVA. En la modalidad de ALQUILER es de: 72€ +IVA<br /><br />

Ponemos a su disposición la fabricación a medida de puertas (medidas máximas de 1200×2500 cm. Para medidas mayores póngase en contacto con nuestro departamento comercial). El precio es de de 735 € +IVA. Dicha opción no está disponible en modalidad de ALQUILER.<br /><br />

Sistema DIR<br /><br />

El sistema DIR es un extra a añadir a la puerta. El coste de añadir el sistema DIR a su puerta es de 168 € +IVA en modalidad de compra, y de 18€ IVA en modalidad de alquiler.<br /><br />

Pago con tarjeta Visa, Visa Electrón y Mastercard:<br /><br />

Una vez en la página de VENTA O ALQUILER compruebe su elección, clique en COMPRAR, rellene los datos del formulario de compra, dirección de envío, etc. y, a continuación, pulse en “Pagar”.<br /><br />

Podrá hacerlo con las tarjetas Visa, Visa Electrón y Mastercard mediante la pasarela segura de pago Banco Sabadell, donde deberá introducir los datos que se soliciten y validar la operación. O mediante Paypal si así lo prefiere.<br /><br />

Recibirá un correo en su mail con el detalle de la factura. Así como un enlace a un video con instrucciones detalladas de instalación de la puerta.<br /><br />

Modificación de datos:<br /><br />

Toda modificación o cambio de los datos asociados a un pedido a Soluciones Técnicas del metal S.L., podrá realizarlo llamando al teléfono 93 5752015 (de 9 a 18h) o bien por correo electrónico a la dirección info@stmseguridad.com, siempre teniendo en cuenta los plazos de entrega señalados en las presentes condiciones generales de compra. De igual forma, Soluciones Técnicas del metal S.L., pone a su disposición la dirección de correo electrónico info@stmseguridad.com para que pueda ejercer los derechos de acceso, rectificación, cancelación y oposición.<br /><br />

Devoluciones / Derecho de desistimiento<br /><br />

Los clientes que adquieran un producto en www.stmseguridad.com tienen el derecho de ejercer libremente el desistimiento de su compra en un plazo de 7 días, a partir del momento en que se recibe el producto y es posible examinarlo. A pesar de lo comentado, y en virtud de lo establecido en el artículo 45 de la Ley 7/1996, de 15 de enero, de Ordenación del Comercio Minorista, quedan exceptuados de este derecho aquellos bienes que no puedan ser devueltos o puedan deteriorarse o caducar con rapidez.<br /><br />

De acuerdo a este derecho, el usuario tiene la posibilidad en todo momento de renunciar a la compraventa efectuada, aún habiéndose producido la confirmación del pedido y el pago del mismo. Esta renuncia dará lugar a la devolución del importe previamente pagado.<br /><br />

Cuando el producto sea devuelto y se compruebe que está en perfecto estado y con su embalaje original, se realizará el abono correspondiente al importe de la compra, excluyendo los gastos de transporte. Soluciones Técnicas del metal S.L., dispondrá entonces de un periodo máximo de 30 días naturales, desde el momento de la comunicación por parte del cliente del desistimiento o devolución, para retornar el importe de la compra al cliente, que se realizará mediante transferencia bancaria.<br /><br />

Los clientes que deseen ejercer su derecho de desistimiento o devolución deberán comunicarlo por escrito a info@stmseguridad.com, siendo Soluciones Técnicas del metal S.L., quien se pondrá en contacto con ellos para coordinar la recogida de los artículos, la comprobación del estado de los mismos y, si todo es correcto, rembolsar el importe de éstos.<br /><br />

Consulte el apartado “Modalidades de compra” para las condiciones específicas del modo de alquiler de la puerta.<br /><br />

Productos defectuosos, en mal estado o con el embalaje roto<br /><br />

Indíquelo en el albarán que le hará firmar la agencia de transporte. Haga fotos del producto y del embalaje y envíenoslo con un pequeño comentario a info@stmseguridad.com<br /><br />

Si el producto fuera defectuoso, contacte directamente con Soluciones Técnicas del metal S.L. a través del correo info@stmseguridad.com, o a través del teléfono 93 5752015, en un plazo máximo de 7 días desde la recepción de la compra.<br /><br />

Garantía Aplicable<br /><br />

El consumidor está en su pleno derecho, de disfrutar durante dos años del plazo estipulado, como garantía, a partir de la recepción de la mercancía, quedara excluido cualquier uso indebido, así como manipulación y rotura, causada directamente por el usuario y/o consumidor final del producto.<br /><br />

5.- Propiedad intelectual<br /><br />

Los contenidos suministrados por Soluciones Técnicas del metal S.L., están sujetos a los derechos de propiedad intelectual e industrial y son titularidad exclusiva de Soluciones Técnicas del metal S.L., Mediante la adquisición de un producto o servicio, Soluciones Técnicas del metal S.L., no confiere al adquirente ningún derecho de alteración, explotación, reproducción, distribución o comunicación pública sobre el mismo, reservándose Soluciones Técnicas del metal S.L., todos estos derechos. La cesión de los citados derechos precisará el previo consentimiento por escrito por parte de Soluciones Técnicas del metal S.L., El cliente no podrá poner a disposición de terceras personas dichos contenidos.La propiedad intelectual se extiende, además del contenido incluido en Soluciones Técnicas del metal S.L., a sus gráficos, logotipos, diseño, imágenes y código fuente utilizado para su programación.<br /><br />

6.- Uso del servicio y responsabilidades<br /><br />

Soluciones Técnicas del metal S.L., no se hace responsable del contenido de los enlaces a otras páginas Web que no sean titularidad suya y que, por tanto, no pueden ser controladas por ésta.<br /><br />

Errores en las conexiones, caso fortuito y fuerza mayor: Soluciones Técnicas del metal S.L., no será responsable de los errores, retrasos en el acceso, retrasos en el sistema o cualquier anomalía que pueda surgir en relación con problemas de carácter general en la red Internet, causas de caso fortuito o fuerza mayor o cualquier otra contingencia totalmente imprevisible y por tanto ajena a la buena fe de la empresa. Soluciones Técnicas del metal S.L., se compromete a tratar de solucionar estas incidencias poniendo todos los medios a su alcance y ofreciendo todo el apoyo necesario al USUARIO para solventar las incidencias de la forma más rápida y satisfactoria posible. Asimismo Soluciones Técnicas del metal S.L., no será responsable de los fallos que por estas causas se puedan producir en las comunicaciones, borrado o transiciones incompletas, de manera que no garantiza que el sitio web esté en todo momento operativo cuando se deba a cuestiones no imputables a Soluciones Técnicas del metal S.L. que ésta no pueda resolver con los medios que están a su alcance.<br /><br />

Uso del sitio web: Soluciones Técnicas del metal S.L., no será responsable en ningún caso por los errores o daños producidos por el uso ineficiente y de mala fe del servicio por parte del Usuario. Tampoco será responsable Soluciones Técnicas del metal S.L., de las consecuencias mayores o menores por la falta de comunicación entre Soluciones Técnicas del metal S.L., y el Usuario cuando sea imputable al no funcionamiento del correo electrónico facilitado o a la falsedad de los datos proporcionados por el usuario en www.stmseguridad.com.<br /><br />

Responsabilidad: Soluciones Técnicas del metal S.L.,. asume las responsabilidades a que pueda haber lugar por la prestación de los servicios que ofrece en los límites y tal y como se especifica en las distintas Condiciones Generales de Contratación de los servicios que se ponen a disposición del USUARIO.<br /><br />

Responsabilidad Del Usuario:<br /><br />

Buen uso del servicio: El USUARIO está obligado a hacer un buen uso del servicio y por tanto a no utilizarlo de mala fe para cualquier práctica que esté prohibida por la ley o rechazada por los usos mercantiles. Soluciones Técnicas del metal S.L., queda facultada por medio de las presentes Condiciones Generales, a partir del momento en que tenga conocimiento fehaciente de la realización por parte del USUARIO de cualquier actuación o uso ilegal en general, a poner en conocimiento de las autoridades competentes estas circunstancias y dar de baja al USUARIO o restringirle el acceso al sitio web de Soluciones Técnicas del metal S.L., el USUARIO será el único responsable frente a cualquier reclamación o acción legal, judicial o extrajudicial iniciada por terceros perjudicados directamente por el USUARIO ante Juzgados u otros organismos, asumiendo éste todos los gastos, costes e indemnizaciones que, en su caso, pueda asumir Soluciones Técnicas del metal S.L., si la reclamación es dirigida contra ésta. Asimismo, Soluciones Técnicas del metal S.L.,. colaborará y notificará a la autoridad competente estas incidencias en el momento en que tenga conocimiento fehaciente de que los daños ocasionados constituyan cualquier tipo de actividad ilícita, especialmente en el ámbito de contenidos introducidos por el USUARIO que puedan vulnerar derechos o intereses legítimos de Soluciones Técnicas del metal S.L. o de terceros.<br /><br />

Diligencia debida: El USUARIO se responsabiliza de realizar todas las actuaciones que le sean requeridas con la diligencia debida. Con carácter especial, el USUARIO debe ser diligente en lo que se refiere a la actualización y veracidad de sus datos personales, fundamentalmente del correo electrónico señalado en la compra como vía principal de contacto con Soluciones Técnicas del metal S.L.,.<br /><br />

7.- Privacidad y Protección de Datos Personales<br /><br />

Mediante la entrega de la dirección de correo electrónico u otros datos personales, requisito necesario para la contratación de ciertos servicios, los Clientes dan su permiso para que dichas direcciones sean tratadas y además, utilizadas para enviar comunicaciones comerciales de promoción o publicidad de los servicios y productos ofrecidos por Soluciones Técnicas del metal S.L., pone a disposición de los Clientes la dirección de correo electrónico info@stmseguridad.com, para que estos revoquen el consentimiento prestado. Así como el ejercicio de los derechos de acceso, rectificación, cancelación y oposición garantizados por la legislación vigente.<br /><br />

Soluciones Técnicas del metal S.L., declara que cumple la normativa vigente respecto a la protección de datos, en particular la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal y el Real Decreto 1720/2007, de 21 de Diciembre, que desarrolla la citada ley orgánica.<br /><br />

8.- Nulidad e ineficacia de las Cláusulas<br /><br />

Si cualquier cláusula incluida en estas Condiciones Generales fuese declarada, total o parcialmente, nula o ineficaz, tal nulidad o ineficacia afectará tan sólo a dicha disposición o a la parte de la misma que resulte nula o ineficaz, subsistiendo las Condiciones Generales en todo lo demás, teniéndose tal disposición, o la parte de la misma que resultase afectada, por no puesta.<br /><br />

9.- Notificaciones<br /><br />

Todas las notificaciones, requerimientos, peticiones y otras comunicaciones que hayan de efectuarse por las partes en relación con las presentes Condiciones Generales, deberán realizarse por escrito y se entenderá que han sido debidamente realizadas cuando hayan sido entregadas en mano o bien remitidas por correo ordinario al domicilio de la otra parte o al correo electrónico de ésta, o bien a cualquier otro domicilio o correo electrónico que a estos efectos cada parte pueda indicar a la otra.<br /><br />

10.- Legislación aplicable y jurisdicción competente.<br /><br />

Las presentes condiciones generales se rigen por la Legislación española, siendo competentes los Juzgados y Tribunales españoles para conocer de cuantas cuestiones se susciten sobre la interpretación, aplicación e cumplimiento de las mismas. El usuario, por virtud de su aceptación a las condiciones generales recogidas en este aviso legal, renuncia expresamente a cualquier fuero que, por aplicación de la Ley de Enjuiciamiento Civil vigente pudiera corresponderle.<br /><br />
        </div>
    )

}
