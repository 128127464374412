import React from "react";
import Button from "@mui/material/Button";
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';

export default function Block4() {

    return (
        <div className="block4-product-reja px-md-5-34 px-lg-10-42">
            <Grid container className="grid-container">
                <Grid item xs={6} className="grid-item">
                    <p className="mt-0"><span className="paragraph-thin">STM</span> <span className="paragraph-bold">Reja</span> protege su tranquilidad en un visto y no visto</p>
                </Grid>
                <Grid item xs={6} className="grid-item">
                    <Link href="/contact" sx={{
                        textDecoration: "none"
                    }}>
                        <Button variant="outlined" className="btn-primary-white">Contáctenos</Button>
                    </Link>
                </Grid>
            </Grid>
        </div>
    )

}