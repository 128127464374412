import React from "react";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

export default function Carusel1() {

    return (
        <div className="carousel1-about px-md-20px px-lg-150px element-view-height element-view-height-mobile margin-top-navbar">
            <Grid container sx={{
                height: "100%",
                alignItems: "center",
                alignContent: "center"
            }}>
                <Grid item xs={12}>
                    <h2 className="carousel1-about-title margin-0">La seguridad</h2>
                    <h2 className="carousel1-about-title margin-0">sin control</h2>
                    <h2 className="carousel1-about-title margin-0">no aporta valor</h2>
                </Grid>
                <Grid item xs={12}>
                    <Link href="/contact" sx={{
                        textDecoration: "none"
                    }}>
                        <Button variant="outlined" className="btn-primary-white btn-carousel1-about mb-md-40px">Conózcanos</Button>
                    </Link>
                </Grid>
            </Grid>
        </div>
    )

}