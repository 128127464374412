import React from "react";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Mobile from "../../../assets/uploads/productAlarma/iphone_web.png";

export default function Block2() {

    return (
        <div className="block2-product-alarma px-md-5-34 px-lg-10-42">
            <Grid container className="grid-container">
                <Grid item className="grid-item">
                    <img src={Mobile} className="mobile-image" />
                </Grid>
                <Grid item className="grid-item">
                    <p className="mt-0"><span className="span-bold">1.</span> Sin permanencias 
                    ni costes de instalación.<br /><br />
                    <span className="span-bold">2.</span> Vigilancia y aviso a 
                    las fuerzas de seguridad.<br /><br />
                    <span className="span-bold">3.</span> Kit compuesto por: 
                    Una central bidireccional; 
                    Un detector con cámara; 
                    Dos detectores volumétricos y Cartelería.</p>
                    <Link href="/contact" sx={{
                        textDecoration: "none"
                    }}>
                        <Button variant="contained" className="btn-contained-blue">Solicitar ficha técnica</Button>
                    </Link>
                </Grid>
            </Grid>
        </div>
    )

}