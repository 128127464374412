import React from "react";
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import Link from '@mui/material/Link';

export default function Carusel3() {

    return (
        <div className="carousel3 px-md-20px px-lg-150px py-md-60px element-view-height h-xs-auto pt-lg-124px">
            <Grid container sx={{
                height: "100%",
                alignItems: "center",
                alignContent: "center",
                maxWidth: "1140px"
            }}>
                <Grid item xs={12} sx={{
                    marginBottom: "0px"
                }}>
                    <h2>STM <span>Algoritmo de riesgo.</span></h2>
                    <p>Descubra el <span>nivel exacto de protección</span> que necesita su vivienda o zona de acceso gracias a esta <span>innovadora herramienta desarrollada por STM</span>.</p>
                </Grid>
                <Grid item xs={12}>
                    <Link href="/contact" sx={{
                        textDecoration: "none"
                    }}>
                        <Button variant="outlined" className="btn-primary-white btn-carusel2">Quiero un diagnóstico</Button>
                    </Link>
                </Grid>
            </Grid>
        </div>
    )
}