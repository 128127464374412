import React from "react";
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import Link from '@mui/material/Link';

export default function Block3() {

    return (
        <div className="block3-product-tower px-lg-10-42">
            <Grid container className="grid-container">
                <Grid item xs={12} className="grid-item px-md-5-34">
                    <h2 className="mt-0">3 niveles de protección para una triple seguridad.</h2>
                    <p className="mt-0"><span class="paragraph-thin">STM</span> <span className="paragraph-bold">Towers</span> es un avanzado sistema de alta seguridad que le permite controlar en remoto el interior y el exterior de cualquier instalación de telecomunicaciones:</p>
                </Grid>
                <Grid container className="list-container px-md-5-34">
                    <Grid item xs={12} className="vector-item vector-item-1">
                        <div className="span-container span-margin-bottom">
                            <span className="vector-text"><span className="vector-number">1.</span> Puerta acorazada de alta seguridad con control de accesos al recinto de instalaciones de telecomunicaciones.</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} className="vector-item vector-item-2">
                        <div className="span-container span-margin-bottom">
                            <span className="vector-text"><span className="vector-number">2.</span> Sistema se alarma homologado con sensores y cámaras inalámbricos.</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} className="vector-item vector-item-3">
                        <div className="span-container span-margin-bottom-last">
                            <span className="vector-text"><span className="vector-number">3.</span> Armadura acorazada de alta seguridad para la protección de gabinetes.</span>
                        </div>
                    </Grid>
                    <Link href="/contact" sx={{
                        textDecoration: "none"
                    }}>
                        <Button variant="outlined" className="btn-four-blue">Infórmese</Button>
                    </Link>
                </Grid>
            </Grid>
        </div>
    )

}