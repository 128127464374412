import {BASE_PATH, BASE_PATH_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../Auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH}${BASE_PATH_EXTENSION_ADMIN}/contact`;

export function apiList(props) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};

    return axios.get(`${baseUrl}/list?limit=${props.limit}&offset=${props.offset}&field=${props.field}&sort=${props.sort}`, config)
}


export function apiDelete(id) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()?.primaryUser?.token}`}};
    return axios.delete(`${baseUrl}/delete/${id}`, config)
}
