import React from "react";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import producto from "../../../assets/uploads/productDoor/productos_web12.png";

export default function Block1() {

    return (
        <div className="block1-product-door px-md-5-34 px-lg-10-42 margin-top-navbar">
            <Grid container className="grid-container" sx={{
                height: "100%"
            }}>
                <Grid item xs={6} className="grid-item" sx={{
                    height: "max-content"
                }}>
                    <h3 className="mt-0">STM</h3>
                    <h1 className="mt-0 last-title">Door</h1>
                    <h2 className="mt-0 h2-text">El corazón de una puerta antiokupa</h2>
                    <p className="mt-0">con el aspecto de una puerta convencional.</p>
                    <Link href="/contact" sx={{
                        textDecoration: "none"
                    }}>
                        <Button variant="outlined" className="btn-four-blue">Infórmese</Button>
                    </Link>
                </Grid>
                <Grid item xs={6} className="grid-item" sx={{
                    height: "max-content"
                }}>
                    <img src={producto} />
                </Grid>
            </Grid>
        </div>
    )

}