import React from "react";
import Grid from '@mui/material/Grid';
import ReactPlayer from 'react-player'
import Video from '../../../assets/uploads/video/Vídeo_animacion_logo_24.mp4'
import Miniatura from '../../../assets/uploads/video/miniatura.png'
import VideoButton from '../../../assets/uploads/video/button.png'

export default function CaruselVideo() {

    return (
        <div className="carousel-video h-xs-auto">
            <Grid container sx={{
                width: "100%",
                alignItems: "center",
                alignContent: "center"
            }}>
                <Grid item xs={12} className="video-container">
                    <ReactPlayer url={Video} playing light={Miniatura} playIcon={<button><img src={VideoButton} /></button>} controls={true} width="100%" height="100%" />
                </Grid>
            </Grid>
        </div>
    )
}
