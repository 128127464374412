import React from "react";
import Grid from '@mui/material/Grid';

export default function Block3() {

    return (
        <div className="block3-product-alarma px-lg-10-42">
            <Grid container className="grid-container">
                <Grid item xs={12} className="grid-item px-md-5-34">
                    <h2 className="mt-0">Tenga ojos en todas partes en todo momento.</h2>
                    <p className="mt-0">Con la <span class="paragraph-thin">STM</span> <span className="paragraph-bold">Video Alarma</span> dispone de un servicio 24 horas con soporte y asistencia 365 días al año.</p>
                </Grid>
            </Grid>
        </div>
    )

}