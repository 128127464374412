import * as React from 'react';
import {Box, Grid, IconButton} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {RouteUser} from "../../../routes/RouteUser";
import {Link} from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Menu(props) {
    const {handleClose, open} = props

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            className="menu-dialog"
            PaperProps={{
                style: {
                    backgroundColor: '#283582',
                    boxShadow: 'none',
                },
            }}

        >
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        className="close-icon"
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                    <Link to={RouteUser.ROOT.path}>Home</Link>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <Link to={RouteUser.ABOUT.path}>About</Link>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <Link to={RouteUser.PRODUCT.path}>Productos</Link>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <Link to={RouteUser.CONTACT.path}>Contacto</Link>
                </Grid>
            </Grid>

        </Dialog>
    )
}
