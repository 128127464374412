import UserManagement from "../pages/Admin/User/Management"
import UserEdit from "../pages/Admin/User/Fields"
import UserCreate from "../pages/Admin/User/Fields"
import MediaManagement from "../pages/Admin/Media/Management"
import ContactManagement from "../pages/Admin/Contact/Management"
import Testing from "../pages/Admin/Testing"

const baseTitle = "Offing - "

export const RouteAdmin = {
    USER_MANAGEMENT: {
        path: "/admin/users/management",
        page: UserManagement,
        title: `${baseTitle}User Management`,
        menu: "users",
        roles: ["ROLE_ADMIN"]
    },
    USER_EDIT: {
        path: "/admin/users/edit",
        params: "/:id",
        page: UserEdit,
        title: `${baseTitle}User Edit`,
        menu: "users",
        roles: ["ROLE_ADMIN"]
    },
    USER_CREATE: {
        path: "/admin/users/create",
        page: UserCreate,
        title: `${baseTitle}User Create`,
        menu: "users",
        roles: ["ROLE_ADMIN"]
    },
    MEDIA_MANAGEMENT: {
        path: "/admin/media/management",
        page: MediaManagement,
        title: `${baseTitle}Media Management`,
        menu: "media",
        roles: ["ROLE_ADMIN"]
    },
    CONTACT_MANAGEMENT: {
        path: "/admin/contact/management",
        page: ContactManagement,
        title: `${baseTitle}Contact Management`,
        menu: "contact",
        roles: ["ROLE_ADMIN"]
    },
    TESTING: {
        path: "/admin/testing",
        page: Testing,
        title: "",
        menu: "",
        roles: ["ROLE_ADMIN"]
    }
}
