import React from "react";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Group3 from "../../../assets/uploads/Group_3.png";

export default function Block1() {

    return (
        <div className="block1-products px-md-5-34 px-lg-10-42 element-view-height element-view-height-mobile margin-top-navbar">
            <Grid container className="grid-container" sx={{
                height: "100%",
                alignItems: "center",
                alignContent: "center"
            }}>
                <Grid item xs={12} className="grid-item" sx={{
                    height: "max-content"
                }}>
                    <img src={Group3} alet="" className="block3-img" alt=""/>
                    <h1>Las soluciones tecnológicas más avanzadas del mercado</h1>
                </Grid>
            </Grid>
        </div>
    )

}