import React, { useRef, useState } from "react";
import Block1 from "./Block1";
import Block2 from "./Block2";
import BasicFooter from "../../../components/Layouts/User/BasicFooter";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";

export default function Contact() {
  
    return (
        <div className="contact">
            <Block2 />
            <Block1 />
            <footer className="footer">
            <Box
                component="footer"
                sx={{
                    py: '21px',
                    px: '20px',
                    mt: 'auto',
                    backgroundColor: "#3362AC",
                    color: "white",
                    textAlign: "center"
                }}
            >
                <Container sx={{
                    px: 0
                }}>
                    ® <strong className="footer-text-bold">STM Security and Intelligent Control </strong>
                    | <Link color="inherit" style={{ textDecoration: 'none', fontFamily: 'RidleyGroteskRegular' }} href="/politica-de-cookies" target="_blank" >Política de Cookies</Link>
                    | <Link color="inherit" style={{ textDecoration: 'none', fontFamily: 'RidleyGroteskRegular' }} href="/condiciones-generales-de-compra" target="_blank" >Condiciones de compra</Link>
                    | <Link color="inherit" style={{ textDecoration: 'none', fontFamily: 'RidleyGroteskRegular' }} href="/aviso-legal" target="_blank" > Aviso legal</Link>
                </Container>
            </Box>
            </footer>
        </div>
    )
}
