import React, {useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import {apiCreate, apiGetUser, apiUpdate} from "../../../../services/Admin/user";
import InputText from "../../../../components/Inputs/InputText";
import InputPassword from "../../../../components/Inputs/InputPassword";
import InputSwitch from "../../../../components/Inputs/InputSwitch";
import InputSelect from "../../../../components/Inputs/InputSelect";
import MsgError from "../../../../components/MsgError";
import MsgSuccess from "../../../../components/MsgSuccess";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import {Button, Grid, Paper} from "@mui/material";
import {useTranslation} from "react-i18next";
import Avatar from "@mui/material/Avatar";
import {LoadingButton} from "@mui/lab";
import {RouteAdmin} from "../../../../routes/RouteAdmin";
import {RouteUser} from "../../../../routes/RouteUser";
import {apiImpersonate} from "../../../../services/Auth/login";
import SelectMedia from "../../../../components/Admin/SelectMedia";
import LoadingBar from "../../../../components/LoadingBar";

export default function Edit() {
    const {id} = useParams()
    const history = useHistory()
    const {auth, login} = useAuth()
    const {t} = useTranslation()

    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues)
    const [loading, setLoading] = useState(false)
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)
    const [avatar, setAvatar] = useState([])

    const optionsRole = [{id: "ROLE_USER", name: "Usuario"}, {id: "ROLE_ADMIN", name: "Administrador"}]

    const [checkedAccess, setCheckedAccess] = useState(true)

    const myRef = useRef(null)
    // Llamamos a la API para obtener los datos del usuario
    useEffect(() => {
        if (id) {
            void getUser()
        } else {
            setLoadingGetInfo(false)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // Esperamos respuesta de media, para obtener el avatar
        if (avatar.length > 0){
            state["mediaId"] = avatar[0].id
            state["mediaUrl"] = avatar[0].url
            setState({...state})
        }
    }, [avatar]) // eslint-disable-line react-hooks/exhaustive-deps

    const getUser = () => {
        setMsgError([])

        apiGetUser(id).then(resp => {
            setState(initialValues(resp.data?.data))
            setLoadingGetInfo(false)
            if (resp.data?.data?.isActive) {
                setCheckedAccess(true)
            } else {
                setCheckedAccess(false)
            }
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data?.msg)
        })
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleChangeChecked = (event) => {
        state[event.target.name] = event.target.checked
        setState({...state})
        if (event.target.checked) {
            setCheckedAccess(true)
        } else {
            setCheckedAccess(false)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setLoading(true)
        setMsgError([])
        setMsgSuccess([])

        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            myRef.current.scrollIntoView()
            return
        }

        if (id) {
            void updateUser()
        } else {
            void createUser()
        }
    }

    const updateUser = () => {
        apiUpdate(state).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const createUser = () => {
        apiCreate(state).then(resp => {
            history.push(RouteAdmin.USER_MANAGEMENT.path)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const clickImpersonate = () => {
        setMsgError([])
        apiImpersonate(id).then(resp => {
            impersonate(resp.data?.token)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
        })
    }

    const impersonate = async (token) => {
        await login({
            primaryUser: token,
            secondaryUser: auth?.secondaryUser,
        })
        history.push(RouteUser.ROOT.path);
    }

    return (
        <>
            <div ref={myRef}></div>
            <HeaderPage title="Configuración de la Cuanta" urlBack={RouteAdmin.USER_MANAGEMENT.path}/>

            <MsgError msg={msgError}/>
            <MsgSuccess msg={msgSuccess}/>

            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <Paper sx={{display: 'flex', flexDirection: 'column'}}>
                        <LoadingBar loading={loadingGetInfo} marginTop={5}/>
                        <Grid container spacing={3} sx={{p: 2}}>
                            <Grid item xs={12} md={12} lg={12}>
                                <h4 className="p-0 mt-0">Settings</h4>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={3}>

                                    <Grid item xs={12} style={{paddingTop: "0px"}}>
                                        <Avatar
                                            sx={{width: 100, height: 100}}
                                            src={state.mediaUrl}
                                            variant="rounded"
                                        />
                                        <br/>

                                        <SelectMedia
                                            btnName="avatar"
                                            setSelectElements={setAvatar}
                                            selectElements={avatar}
                                            selectedMultiple={false}
                                            onlyShow="image"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputText
                                            label="Nombre"
                                            required
                                            name="name"
                                            value={state?.name}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputText
                                            label="Apellidos"
                                            required
                                            name="surnames"
                                            value={state?.surnames}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputText
                                            label="Email"
                                            required
                                            name="email"
                                            value={state?.email}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Paper sx={{display: 'flex', flexDirection: 'column'}}>
                        <LoadingBar loading={loadingGetInfo} marginTop={5}/>
                        <Grid container spacing={3} sx={{p: 2}}>
                            <Grid item xs={12} md={12} lg={12}>
                                <h4 className="p-0 mt-0">Seguridad</h4>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} style={{paddingTop: "0px"}}>
                                        <InputSelect
                                            label="Role"
                                            name="role"
                                            required
                                            onChange={handleChange}
                                            value={state?.role}
                                            options={optionsRole}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputPassword
                                            label="Contraseña"
                                            name="password"
                                            value={state?.password}
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <InputSwitch
                                            label="Acceso a la plataforma"
                                            name="isActive"
                                            checked={state?.isActive}
                                            onChange={handleChangeChecked}
                                        />
                                    </Grid>

                                    {id && (
                                        <Grid item xs={12}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                type="button"
                                                size="small"
                                                fullWidth
                                                onClick={clickImpersonate}
                                            >
                                                Impersonar
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" spacing={3} className="p-20">
                <Grid item xs={6} md={3} lg={3}>
                    <LoadingButton
                        onClick={handleSubmit}
                        loading={loading}
                        loadingPosition="start"
                        variant="contained"
                        fullWidth
                    >
                        {id ? t('Modificar') : t('Crear')}
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        id: props?.id || "",
        name: props?.name || "",
        surnames: props?.surnames || "",
        email: props?.email || "",
        password: "",
        isActive: props?.isActive || true,
        role: props?.role || "",
        mediaId: props?.mediaId || "",
        mediaUrl: props?.mediaUrl || ""
    }
}

function validationSchema(schema) {

    let error = []

    if (!schema.name) {
        error.push("El nombre es obligatorio")
    }
    if (!schema.email) {
        error.push("El email es obligatorio")
    }
    // if (!schema.password) { error.push("La contraseña es obligatoria") }

    return error
}
