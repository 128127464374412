import React from "react";
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import Link from '@mui/material/Link';
import vector1 from "../../../assets/uploads/productPaok/Group16.svg"
import vector2 from "../../../assets/uploads/productPaok/Group8.svg"
import vector3 from "../../../assets/uploads/productPaok/Group6.svg"
import vector4 from "../../../assets/uploads/productPaok/Group10.svg"

export default function Block3() {

    return (
        <div className="block3-product-paok px-md-5-34 px-lg-10-42">
            <Grid container className="grid-container">
                <Grid item xs={12} className="grid-item">
                    <h2 className="mt-0">Su seguridad es para enmarcar.</h2>
                    <p className="mt-0"><span className="paragraph-thin">STM</span> <span className="paragraph-bold">AntiOkupa</span> se instala sobre
                    el marco de la puerta principal de
                    la vivienda sin alterarlo ni dañarlo:</p>
                </Grid>
                <Grid item xs={12} className="vector-item vector-item-1">
                    <div className="span-container span-margin-bottom">
                        <span className="vector-text">Variedad de acabados en madera.</span>
                    </div>
                    <img src={vector1} className="img-vector img-vector-1" />
                </Grid>
                <Grid item xs={12} className="vector-item vector-item-2">
                    <div className="span-container span-margin-bottom">
                        <span className="vector-text">Evita los tapiados y las antiestéticas chapas metálicas.</span>
                    </div>
                    <img src={vector2} className="img-vector img-vector-2" />
                </Grid>
                <Grid item xs={12} className="vector-item vector-item-3">
                    <div className="third-vector-div">
                        <div className="span-container span-margin-bottom-third">
                            <span className="vector-text">Fácil instalación y rápido desmontaje.</span>
                        </div>
                        <img src={vector3} className="img-vector img-vector-3" />
                    </div>
                </Grid>
                <Grid item xs={12} className="vector-item vector-item-4">
                    <div className="last-vector-div">
                        <div className="span-container span-margin-bottom span-margin-bottom-last">
                            <span className="vector-text">Disponible para alquiler y venta.</span>
                        </div>
                        <img src={vector4} className="img-vector img-vector-4" />
                    </div>
                </Grid>
                <Link href="/contact" sx={{
                    textDecoration: "none"
                }}>
                    <Button variant="outlined" className="btn-four-blue">Infórmese</Button>
                </Link>
            </Grid>
        </div>
    )

}