import Homepage from "../pages/User/Homepage"
import About from "../pages/User/About";
import Contact from "../pages/User/Contact";
import Products from "../pages/User/Products";
import ProductAntiOkupa from "../pages/User/ProductAntiOkupa";
import ProductAntiOkupaElectronica from "../pages/User/ProductAntiOkupaElectronica";
import ProductReja from "../pages/User/ProductReja";
import ProductDoor from "../pages/User/ProductDoor";
import ProductGravity from "../pages/User/ProductGravity";
import ProductMot from "../pages/User/ProductMot";
import ProductAlarma from "../pages/User/ProductAlarma";
import ProductConan from "../pages/User/ProductConan";
import ProductTower from "../pages/User/ProductTower";
import ProductSentinel from "../pages/User/ProductSentinel";
import PoliticaCookies from "../pages/User/PoliticaCookies";
import CondicionesCompra from "../pages/User/CondicionesCompra";
import AvisoLegal from "../pages/User/AvisoLegal";

const baseTitle = "STM - "

export const RouteUser = {
    ROOT: {
        path: "/",
        page: Homepage,
        title: "STM Security & Intelligent Control - Sus activos siempre activos",
        metaDescription: "Aseguramos la protección integral de las viviendas temporalmente vacías con las soluciones tecnológicas más avanzadas del mercado.",
        hiddenFooter: true,
        productHeader: false,
        roles: []
    },
    ABOUT: {
        path: "/about",
        page: About,
        title: `${baseTitle}La seguridad sin control no aporta valor`,
        metaDescription: "La solución integral y modular que garantiza la seguridad y el control de accesos de los grandes y pequeños tenedores de inmuebles.",
        hiddenFooter: true,
        productHeader: false,
        roles: []
    },
    CONTACT: {
        path: "/contact",
        page: Contact,
        title: `${baseTitle}A su servicio en todo momento`,
        metaDescription: "Sede central: Carretera de Santiga 86-90, 08291 Ripollet, Barcelona; Teléfono +34 914  267 210 +34 935 752 015; Email: info@stmseguridad.com",
        hiddenFooter: true,
        productHeader: false,
        roles: []
    },
    PRODUCT: {
        path: "/products",
        page: Products,
        title: `${baseTitle}Las soluciones tecnológicas más avanzadas del mercado`,
        metaDescription: "Todos nuestros productos pueden interconectarse entre sí para aportar la máxima seguridad y un control inteligente de cada activo inmobiliario.",
        hiddenFooter: true,
        productHeader: true,
        roles: []
    },
    PRODUCT_ANTI_OKUPA: {
        path: "/producto-anti-okupa",
        page: ProductAntiOkupa,
        title: "STM Antiokupa - Una puerta contra todo y contra todos",
        metaDescription: "La puerta más segura y ensayada RC4 se instala sobre el marco de la puerta principal  de la vivienda sin alterarlo ni dañarlo.",
        hiddenFooter: true,
        productHeader: true,
        roles: []
    },
    PRODUCT_ANTI_OKUPA_ELECTRICA: {
        path: "/producto-electronica",
        page: ProductAntiOkupaElectronica,
        title: "STM Antiokupa Electrónica - Nada escapa a su control",
        metaDescription: "La primera puerta aintiokupa electronica RC4 con cerradura motorizada de alta  seguridad que detecta cualquier tipo de intrusión.",
        hiddenFooter: true,
        productHeader: false,
        roles: []
    },
    PRODUCT_REJA: {
        path: "/producto-reja",
        page: ProductReja,
        title: "STM Reja - Estructura telescópica modular de alta resistencia",
        metaDescription: "Se adapta a todas las medidas y necesidades para proteger su tranquilidad en un visto  y no visto. Sin obras, sin daños y sin demoras.",
        hiddenFooter: true,
        productHeader: true,
        roles: []
    },
    PRODUCT_DOOR: {
        path: "/producto-door",
        page: ProductDoor,
        title: "STM Door - La puerta a su tranquilidad con control remoto",
        metaDescription: "El corazón de una puerta antiokupa con el aspecto de una puerta convencional. Doble  seguridad para una doble tranquilidad.",
        hiddenFooter: true,
        productHeader: true,
        roles: []
    },
    PRODUCT_GRAVITY: {
        path: "/producto-gravity",
        page: ProductGravity,
        title: "STM Gravity - La primera cerradura antiokupa del mundo",
        metaDescription: "Una cerradura nunca vista que se instala en el interior del inmueble sin causar ningún  daño. Controle cualquier puerta desde cualquier parte.",
        hiddenFooter: true,
        productHeader: true,
        roles: []
    },
    PRODUCT_MOT: {
        path: "/producto-mot",
        page: ProductMot,
        title: "STM Mot - Controle cualquier zona de acceso comunitario",
        metaDescription: "Disfrute de la última tecnología que le permitirá controlar cualquier tipo de acceso sin  cerraduras, sin llaves y sin imprevistos.",
        hiddenFooter: true,
        productHeader: true,
        roles: []
    },
    PRODUCT_VIDEO_ALARMA: {
        path: "/producto-video-alarma",
        page: ProductAlarma,
        title: "STM VideoAlarma - Tenga ojos en todas partes en todo momento",
        metaDescription: "Dispone de central de alarma autónoma y un servicio 24 horas con soporte y asistencia para asegurar su tranquilidad todos los días del año.",
        hiddenFooter: true,
        productHeader: true,
        roles: []
    },
    PRODUCT_CONAN: {
        path: "/producto-conan",
        page: ProductConan,
        title: "STM Conan - Un sistema bárbaro para ahuyentar a los okupas",
        metaDescription: "Un sistema exclusivo de protección temporal y reversible que maximiza la seguridad  de la vivienda temporalmente deshabitada.",
        hiddenFooter: true,
        productHeader: true,
        roles: []
    },
    PRODUCT_TOWERS: {
        path: "/producto-towers",
        page: ProductTower,
        title: "STM Towers - La tecnología que protege a la tecnología",
        metaDescription: "3 niveles de protección. Porque sus instalaciones tecnológicas pueden estar en  cualquier parte del mundo y su seguridad también.",
        hiddenFooter: true,
        productHeader: true,
        roles: []
    },
    PRODUCT_SENTINEL: {
        path: "/producto-sentinel",
        page: ProductSentinel,
        title: "STM Sentinel - La seguridad que no se ve, pero se siente",
        metaDescription: "La solución tecnológica más económica y silenciosa para mantener las viviendas temporalmente deshabitadas controladas en todo momento.",
        hiddenFooter: true,
        productHeader: true,
        roles: []
    },
    POLITICA_DE_COOKIES: {
        path: "/politica-de-cookies",
        page: PoliticaCookies,
        title: `${baseTitle}Politica de cookies`,
        hiddenFooter: true,
        productHeader: true,
        roles: []
    },
    CONDICIONES_GENERALES_DE_COMPRA: {
        path: "/condiciones-generales-de-compra",
        page: CondicionesCompra,
        title: `${baseTitle}Condiciones generales de compra`,
        hiddenFooter: true,
        productHeader: true,
        roles: []
    },
    AVIDEO_LEGAL: {
        path: "/aviso-legal",
        page: AvisoLegal,
        title: `${baseTitle}Aviso legal`,
        hiddenFooter: true,
        productHeader: true,
        roles: []
    }
}
