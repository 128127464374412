import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import imgLogo from "../../../assets/uploads/Logo_STM.svg"
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import {LINK_FACEBOOK, LINK_INSTAGRAM, LINK_TWITTER} from "../../../utils/constants";
import Button from "@mui/material/Button";

export default function Footer() {


    return (
        <>
            <CssBaseline />
            <Grid container
                sx={{
                    backgroundColor: "#ffffff",
                    pb: "4.8%",
                    pt: "10.4%"
                }}
                className="grid-container">
                <Grid item xs={12} md={6}
                    sx={{
                        backgroundColor: "#ffffff",
                        pb: 3,
                        paddingLeft:"10%"
                    }}
                    className="responsive-first-column">
                    <div className="caja-logo text-center mx-auto">
                        <img src={imgLogo} alet="integralVNG" className="logo-stm" alt="STM Logo"/>
                    </div>
                    <div className="text-center mx-auto">
                        <a href={LINK_FACEBOOK} target="_blank"><FacebookIcon className="facebook"/></a>
                        <a href={LINK_TWITTER} target="_blank"><TwitterIcon className="twitter"/></a>
                        <a href={LINK_INSTAGRAM} target="_blank"><InstagramIcon className="instagram"/></a>
                    </div>


                </Grid>
                <Grid item xs={12} md={6} xs={8} sx={{
                        backgroundColor: "#ffffff",
                        paddingRight: "10%"
                    }}
                    className="responsive-second-column">
                    <div>
                        <p className="footer-text-bold footer-text-blue">Sede central</p>
                        <p className="footer-text footer-text-blue">Carretera de Santiga 86-90</p>
                        <p className="footer-text footer-text-blue">08291 Ripollet </p>
                        <p className="footer-text footer-text-blue">Barcelona</p>
                    </div>
                    <div className="footer-phone">
                        <p className="footer-text footer-text-blue">+34 914 267 210</p> 
                        <p className="footer-text footer-text-blue">+34 935 752 015</p>
                        <p className="footer-text-bold footer-text-blue"><Link href="mailto:info@stmseguridad.com" sx={{color: "inherit",textDecoration: "none"}}>info@stmseguridad.com</Link></p>
                    </div>
                    <div>
                        <Link href="/contact" sx={{
                            textDecoration: "none"
                        }}>
                            <Button variant="outlined" className="btn-footer">Contáctenos</Button>
                        </Link>
                    </div>
                </Grid>
            </Grid>
            <Box
                component="footer"
                sx={{
                    py: '21px',
                    px: '20px',
                    mt: 'auto',
                    backgroundColor: "#3362AC",
                    color: "white",
                    textAlign: "center"
                }}
            >
                <Container sx={{
                    px: 0
                }}>
                    ® <strong className="footer-text-bold">STM Security and Intelligent Control </strong>
                    | <Link color="inherit" style={{ textDecoration: 'none', fontFamily: 'RidleyGroteskRegular' }} href="/politica-de-cookies" target="_blank" >Política de Cookies</Link>
                    | <Link color="inherit" style={{ textDecoration: 'none', fontFamily: 'RidleyGroteskRegular' }} href="/condiciones-generales-de-compra" target="_blank" >Condiciones de compra</Link>
                    | <Link color="inherit" style={{ textDecoration: 'none', fontFamily: 'RidleyGroteskRegular' }} href="/aviso-legal" target="_blank" > Aviso legal</Link>
                </Container>
            </Box>

        </>

    );
}
