import React from "react";
import Button from "@mui/material/Button";
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';

export default function Carusel1() {

    return (
        <div className="carousel1 px-md-20px px-lg-150px element-view-height element-view-height-mobile margin-top-navbar">
            <Grid container sx={{
                height: "100%",
                alignItems: "center",
                alignContent: "center"
            }} className="align-content-md-end">
                <Grid item xs={12}>
                    <h2 className="carousel1-title margin-0">Sus</h2>
                    <h2 className="carousel1-title margin-0">activos</h2>
                    <h2 className="carousel1-title margin-0">siempre</h2>
                    <h2 className="carousel1-title margin-0">activos</h2>
                </Grid>
                <Grid item xs={12}>
                    <Link href="/contact" sx={{
                        textDecoration: "none"
                    }}>
                        <Button variant="outlined" className="btn-four-blue btn-carousel1 mb-md-40px">Conózcanos</Button>
                    </Link>
                </Grid>
            </Grid>
        </div>
    )

}