import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import imgLogo from "../../../assets/uploads/Logo_STM.svg"
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import Button from "@mui/material/Button";
import Footer from "./Footer";

export default function CarouselFooter() {


    return (
        <>
            <Grid item xs={12} className="footer-title">
                <h2 className="carousel1-title margin-0" style={{paddingTop: 40}}>Sus</h2>
                <h2 className="carousel1-title margin-0">activos</h2>
                <h2 className="carousel1-title margin-0">siempre</h2>
                <h2 className="carousel1-title margin-0">activos</h2>
            </Grid>
            <Footer/>
        </>

    );
}
