import React from "react";
import {FormControl, InputLabel, MenuItem, Select, OutlinedInput, Hidden, useTheme, useMediaQuery} from "@mui/material";

export default function InputSelect(props) {

    const {label, name, value, options, onChange, required} = props

    const smUp = useMediaQuery(useTheme().breakpoints.up("md"))
    
    return (
        <>
            {smUp && <p>{label}</p>}
            <FormControl fullWidth size={"small"}>
                <Select
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    value={value}
                    name={name}
                    onChange={onChange}
                >
                    {!smUp && <MenuItem value=""><em>{label}</em></MenuItem>}
                    {options.map((option, key) => {
                        return (<MenuItem key={key} value={option.id}>{option.name}</MenuItem>)
                    })}
                </Select>
            </FormControl>
        </>
    )
}