import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Management from "../../components/Admin/Media/Management";
import {Container, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useEffect} from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

/**
 * Muestras el modal, para seleccionar media. Te devuelve un objeto con los elementos que has seleccionado.
 *
 * ## PROPS
 * - **btnName** *string*
 * - **selectElements**
 * - **setSelectElements**
 * - **selectedMultiple** *boolean*
 * - **onlyShow** *string* (e.g: image)
 */
export default function SelectMedia(props) {

    const {btnName, selectElements, setSelectElements, selectedMultiple, onlyShow} = props
    const [open, setOpen] = React.useState(false)
    const [selectElementsIn, setSelectElementsIn] = React.useState(selectElements)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleOk = () => {
        setSelectElements(selectElementsIn)
        handleClose()
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
                maxWidth="md"
            >
                <DialogContent dividers={'paper'} style={{backgroundColor: '#f3f3f3',}}>
                    <Container sx={{mb: 4}}>
                        <Management
                            selectElementsReturn={selectElementsIn}
                            setSelectElementsReturn={setSelectElementsIn}
                            canBeSelected={true}
                            selectedMultiple={selectedMultiple}
                            onlyShow={onlyShow}
                        />
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" onClick={handleOk}>Select image</Button>
                </DialogActions>
            </Dialog>

            <Button variant="outlined" onClick={handleClickOpen} fullWidth>
                {btnName}
            </Button>
        </div>
    )
}
