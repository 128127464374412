import { BASE_PATH, BASE_PATH_EXTENSION } from "../../utils/constants";
import axios from 'axios';
import {getToken} from "./token";

const baseUrl = `${BASE_PATH}${BASE_PATH_EXTENSION}/auth`;
const config = {headers: { 'Authorization': `Bearer ${getToken()}`}};

export function apiLogin(data) {
    return axios.post(`${BASE_PATH}/api/login-check`, data)
}

export function apiRegister(data) {
    return axios.post(`${baseUrl}/register`, data)
}

export function apiSendRecoveryEmail(data) {
    return axios.post(`${baseUrl}/send-recovery-email`, data)
}

export function apiRecoverPassword(data, token) {
    return axios.post(`${baseUrl}/recover-password/${token}`, data)
}

export function apiImpersonate(id) {
    return axios.get(`${baseUrl}/impersonate/${id}`, config)
}
