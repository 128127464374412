import React from "react";

export default function Block1() {

    return (
        <div className="politica-cookies px-md-5-34 px-lg-10-42 margin-top-navbar">
Uso de cookies<br /><br />
En cumplimiento de lo establecido en la Ley de Cookies (UE) y siguiendo las directrices de la Agencia Española de Protección de Datos, SOLUCIONES TECNICAS DEL METAL S.L. informa a los usuarios de los siguientes extremos:<br /><br />

Este Web utiliza cookies propias y de terceros para ofrecerle una mejor experiencia y servicio. Al navegar o utilizar nuestros servicios el usuario acepta el uso que hacemos de las cookies. Sin embargo, el usuario tiene la opción de impedir la generación de cookies y la eliminación de las mismas mediante la selección de la correspondiente opción en su Navegador. En caso de bloquear el uso de cookies en su navegador es posible que algunos servicios o funcionalidades de la página Web no estén disponibles.<br /><br />

¿Qué es una cookie?<br /><br />
Una cookie es un pequeño fichero de texto que se almacena en su navegador cuando visita casi cualquier página web. Su utilidad es que la web sea capaz de recordar su visita cuando vuelva a navegar por esa página. Las cookies suelen almacenar información de carácter técnico, preferencias personales, personalización de contenidos, estadísticas de uso, enlaces a redes sociales, acceso a cuentas de usuario, etc. Sin cookies los servicios ofrecidos por cualquier página se verían mermados notablemente.<br /><br />

Este sitio web puede utilizar las siguientes cookies:<br /><br />

Cookies de sesión<br /><br />
Las cookies de sesión son memorizadas y únicamente tienen validez temporal, es decir, hasta que el usuario finaliza la navegación por la página web. Estas cookies no graban de forma permanente ninguna información en el disco duro de su ordenador.<br /><br />

Cookies de navegación<br /><br />
El objetivo principal de las cookies de navegación es evitar ofrecerles recomendaciones no afines a sus intereses / y ofrecerles propuestas comerciales dirigidas y personalizadas.  Estas cookies de navegación basan su utilidad en el seguimiento temporal de la navegación por Internet. El Usuario tiene la posibilidad de eliminar este tipo de cookies antes de iniciar la navegación por otras páginas del sitio web.<br /><br />

Cookies estadísticas<br /><br />
Las cookies estadísticas permiten conocer la siguiente información:<br />
– La forma de interactuar los usuarios en el sitio web<br />
– Recopila información de forma anónima<br />
– Elabora informes de tendencias de sitios web sin identificar a usuarios individuales<br /><br />

Para realizar las estadísticas de uso de nuestro sitio web utilizamos herramientas estadísticas con un alcance limitado a las funciones descritas en el párrafo anterior y con la finalidad de conocer el nivel de recurrencia de nuestros visitantes y los contenidos que resultan más interesantes. De esta manera podemos concentrar nuestros esfuerzos en mejorar las áreas más visitadas y hacer que el usuario encuentre más fácilmente lo que busca.<br /><br />

Cookies de terceros
En determinadas ocasiones podemos ofrecer a través de nuestra página Web contenidos de terceros. Éstos terceros pueden instalar cookies que escapan de nuestro control.<br /><br />

ESTE SITIO WEB UTILIZA LAS SIGUIENTES COOKIES PROPIAS:
PHPSESSID – Esta cookie es nativa de PHP y permite a la web guardar datos serializados de estado. En esta web se usa para establecer sesiones de usuario pasando datos de estado a través de una cookie temporal también conocida como Cookie de sesión. La cookie PHPSESSID no tiene caducidad establecida ya que desaparece cuando se cierra la web.<br /><br />

ESTE SITIO WEB UTILIZA LAS SIGUIENTES COOKIES DE TERCEROS:
Google Analytics: Almacena cookies para poder elaborar estadísticas sobre el tráfico y volumen de visitas de esta web. Al utilizar este sitio web está consintiendo el tratamiento de información acerca de usted por Google. Por tanto, el ejercicio de cualquier derecho en este sentido deberá hacerlo comunicando directamente con Google.<br /><br />

__utma<p></p>
Caducidad; 2 años desde su creación<br />
Se usa para distinguir usuarios y sesiones. La cookie se crea cuando la librería de javascript se ejecuta y no existe una cookie __utma previa. La cookie se actualiza cada vez que se mandan datos a Google Analytics.<br /><br />

__utmb<br />
Caducidad; 30 mins desde su creación/actualización<br />
Se usa para determinar nuevas sesiones/visitas. La cookie se crea cuando la librería de javascript se ejecuta y no existe una cookie __utmb previa. La cookie se actualiza cada vez que se mandan datos a Google Analytics.<br /><br />

__utmc<br />
Caducidad; Cuando expira la sesión del navegador<br />
No usada en ga.js. Se crea para la interoperatividad con urchin.js. Históricamente esta cookie funcionaba conjuntamente con la cookie __utmb para determinar si un usuario estaba en una nueva sesión/visita.<br /><br />

__utmz<br />
Caducidad; 6 meses desde su creación/actualización<br />
Almacena el origen del tráfico o campaña que explica cómo el usuario ha llegado a la web. La cookie se crea cuando se ejecuta la librería javascript y se actualiza cada vez que se envían datos a Google Analytics.<br /><br />

Redes sociales: La cookie de AddThis cookie está conectada a los informes corporativos de WPP, y está asociada a la función “compartir” desde la que un usuario puede mandar un enlace a sus amigos.<br /><br />

Este es uno de los servicios usados para habilitar la funcionalidad de compartir en medios sociales. Estas cookies dan información analítica acerca de desde donde y cuan a menudo se comparte contenido usando Addthis. También permiten personalizar los botones de compartir. AddThis, propiedad y operado por Clearspring Technologies, es una plataforma para compartir contenido y que provee de analíticas a los autores de dicho contenido.<br /><br />

__atuvc. Se crea y se lee por el javascript de Addthis en el lado cliente para asegurar que el usuario ve un contador actualizado si comparten una página y vuelven a ella antes que la caché del contador se actualice. No se envían datos de esa cookie a AddThis y eliminarla cuando se desactivan las cookies podría causar un comportamiento inesperado de la web. Caduca a los 2 años.<br /><br />

xtc. Usada por AddThis para registrar la actividad social y enlaces compartidos por el usuario. Caduca a los 2 años.<br /><br />

uid. Provee un ID de usuario único asignado por una máquina. Caduca a los 2 años.<br /><br />

loc. Esta es la cookie de geolocalización para que los autores sepan desde donde los usuarios comparten información.<br /><br />

Idiomas: La cookie _icl_current_language es propia de wordpress y se usa para gestionar los contenidos en distintos idiomas de la web.<br /><br />

DESACTIVACIÓN O ELIMINACIÓN DE COOKIES<br /><br />
Sin perjuicio de lo anterior, el Usuario tiene la posibilidad de configurar su navegador para aceptar o rechazar por defecto todas las cookies o para recibir un aviso en pantalla de la recepción de cada cookie y decidir en ese momento su implantación o no en su disco duro. Para ello, le sugerimos la sección de ayuda de su navegador para saber cómo cambiar la configuración que actualmente emplea. También puede utilizar las herramientas de bloqueo de cookies de rastreo como Do Not Track, que puede descargar del sitio: http://www.abine.com/dntdetail.php.<br /><br />

Asimismo, tiene la posibilidad de revocar en cualquier momento el consentimiento prestado para la utilización de cookies por parte de SOLUCIONES TECNICAS DEL METAL S.L. configurando para ello su navegador en los términos previstos en el punto anterior.<br /><br />

Sin perjuicio de lo anterior, tenga en cuenta que la deshabilitación de las mismas podría afectar al correcto funcionamiento de determinadas secciones del sitio Web.<br /><br />

Por favor, consulte las instrucciones y manuales de su navegador para ampliar esta información:<br /><br />

Si utiliza Microsoft Internet Explorer, en la opción de menú Herramientas, seleccionando Opciones de Internet y accediendo a Privacidad.<br /><br />

Si utiliza Firefox, para Mac en la opción de menú Preferencias, seleccionando Privacidad, accediendo al apartado Mostrar Cookies, y para Windows en la opción de menú Herramientas, seleccionando Opciones, accediendo a Privacidad y luego a Usar una configuración personalizada para el historial.<br /><br />

Si utiliza Safari, en la opción de menú Preferencias, seleccionando Privacidad.<br /><br />

Si utiliza Google Chrome, en la opción de menú Herramientas, seleccionando Opciones (Preferencias en Mac), accediendo a Avanzadas y luego en la opción Configuración Contenido de la sección Privacidad, y finalmente marcando Cookies en el diálogo Configuración de contenido.<br /><br />

NOTAS ADICIONALES<br /><br />
Ni esta web ni sus representantes legales se hacen responsables ni del contenido ni de la veracidad de las políticas de privacidad que puedan tener los terceros mencionados en esta política de cookies.<br /><br />

Los navegadores web son las herramientas encargadas de almacenar las cookies y desde este lugar debe efectuar su derecho a eliminación o desactivación de las mismas. Ni esta web ni sus representantes legales pueden garantizar la correcta o incorrecta manipulación de las cookies por parte de los mencionados navegadores.<br /><br />

En algunos casos es necesario instalar cookies para que el navegador no olvide su decisión de no aceptación de las mismas.<br /><br />

En el caso de las cookies de Google Analytics, esta empresa almacena las cookies en servidores ubicados en Estados Unidos y se compromete a no compartirla con terceros, excepto en los casos en los que sea necesario para el funcionamiento del sistema o cuando la ley obligue a tal efecto. Según Google no guarda su dirección IP. Google Inc. es una compañía adherida al Acuerdo de Puerto Seguro que garantiza que todos los datos transferidos serán tratados con un nivel de protección acorde a la normativa europea. Puede consultar información detallada a este respecto en este enlace. Si desea información sobre el uso que Google da a las cookies le adjuntamos este otro enlace.<br /><br />

Para cualquier duda o consulta acerca de esta política de cookies no dude en comunicarse con nosotros a través de la sección de contacto.<br />
        </div>
    )

}