import React, {useState} from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputSelect from "../../../components/Inputs/InputSelect";
import InputText from "../../../components/Inputs/InputText";
import MsgError from "../../../components/MsgError";
import {apiCreate} from "../../../services/User/contact";
import MsgSuccess from "../../../components/MsgSuccess";

export default function Block2() {
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues())

    const optionsHour = [{id: "09:00 - 14:00", name: "09:00 - 14:00"}, {id: "15:00 - 18:00", name: "15:00 - 18:00"}]
    const optionsFormat = [{id: "Pendiente", name: "Pendiente"}, {id: "Baja", name: "Baja"}]

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleChangeCheckbox = (event) => {
        state[event.target.name] = event.target.checked
        setState({...state})
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setMsgError([])
        setMsgSuccess([])
        let error = validationSchema(state)
    
        if (error.length !== 0) {
            setMsgError(error)
            return
        }

        apiCreate(state).then(resp => {
            setMsgSuccess(resp?.data?.msg)
            setState(initialValues())
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
        })
    }

    return (
        <div className="block2 px-md-20px px-lg-150px py-md-60px h-xs-auto margin-top-navbar">
            <Grid container>

                <FormControl>
                    <Grid item xs={12}>
                        <h2 className="mt-0">Podemos ayudarle.</h2>
                        <h4>Rellene sus datos y nos pondremos en contacto con usted a la mayor brevedad posible.</h4>
                    </Grid>

                    <Grid container xs={12}>
                        <Grid container xs={12} sx={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}>
                            <Grid item className="input-column">
                                <InputText
                                    label="Nombre*"
                                    required
                                    name="name"
                                    value={state?.name}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item className="input-column mt-md-30px">
                                <InputText
                                    label="Apellido*"
                                    required
                                    name="surname"
                                    value={state?.surname}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={12} sx={{
                            marginTop: "30px",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}>
                            <Grid item className="input-column">
                                <InputText
                                    label="Teléfono móvil*"
                                    required
                                    name="phone"
                                    value={state?.phone}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item className="input-column mt-md-30px">
                                <InputText
                                    label="Email*"
                                    required
                                    name="email"
                                    value={state?.email}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={12} sx={{
                            marginTop: "30px",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}>
                            <Grid item className="input-column input-select">
                                <InputText
                                    label="Empresa*"
                                    required
                                    name="company"
                                    value={state?.company}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item className="input-column input-select">
                                <InputSelect
                                    name="timeContact"
                                    required={true}
                                    options={optionsHour}
                                    onChange={handleChange}
                                    value={state?.hour}
                                    label="Horario para recibir nuestra llamada"
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={12} sx={{
                            marginTop: "30px"
                        }}>
                            <Grid item xs={12} className="textarea-container">
                                <p className="label">Datos y comentarios importantes para nuestro equipo:</p>
                                <TextareaAutosize name="text" onChange={handleChange} className="textarea-input" minRows={17} aria-label="" placeholder="Datos y comentarios importantes para nuestro equipo:" style={{
                                    width: "100%", backgroundColor: "transparent", borderColor: "#A7B6D8", borderRadius: "4px"
                                }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="checkbox-button-container">
                        <Grid item xs={12} lg={6} className="checkbox-container">
                            <FormControlLabel control={<Checkbox name="newsletter" onChange={handleChangeCheckbox} checked={state?.newsletter}/>} className="first-checkbox" label="Quiero recibir noticias y ofertas de STM Security & Intelligent Control" />
                            <FormControlLabel control={<Checkbox name="checkPrivacidad" onChange={handleChangeCheckbox} checked={state?.checkPrivacidad}/>} className="checkbox-item" label="He leído y acepto expresamente la política de privacidad del portal." />
                            <FormControlLabel control={<Checkbox name="checkLegal" onChange={handleChangeCheckbox} checked={state?.checkLegal}/>} className="checkbox-item" label="He leído y acepto el aviso legal y condiciones de uso del portal." />
                        </Grid>
                        <Grid item xs={12} lg={6} className="button-container">
                            <Button variant="outlined" onClick={handleSubmit} className="btn-four-blue btn-block2">Enviar</Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <br/>
                        <br/>
                        <MsgError msg={msgError}/>
                        <MsgSuccess msg={msgSuccess}/>
                    </Grid>
                </FormControl>
            </Grid>
        </div>
    )
}

function initialValues(props) {
    return {
        name: "",
        surname: "",
        phone: "",
        email: "",
        company: "",
        timeContact: "",
        text: "",
        newsletter: false,
        checkPrivacidad: false,
        checkLegal: false
    }
}

function validationSchema(schema) {

    let error = []

    if (!schema.name) { error.push("El nombre es obligatorio") }
    if (!schema.surname) { error.push("El apellido es obligatorio") }
    if (!schema.phone) { error.push("El Teléfono móvil es obligatorio") }
    if (!schema.email) { error.push("El email es obligatorio") }
    if (!schema.company) { error.push("La Empresa es obligatorio") }
    if (!schema.timeContact) { error.push("El horario para recibir nuestra llamada es obligatorio") }

    if (!schema.checkPrivacidad) { error.push("Debe aceptar He leído y acepto expresamente la política de privacidad del portal.") }
    if (!schema.checkLegal) { error.push("Debe aceptar He leído y acepto el aviso legal y condiciones de uso del portal.") }
    return error
}
