import React from "react";
import Header from "./Header";
import HeaderProduct from "./HeaderProduct";
import BasicFooter from "./BasicFooter";

export default function Basic(props) {
    const { children, hiddenFooter, productHeader } = props

    return (
        <div>
            {productHeader ? <HeaderProduct /> : <Header />}
            {children}
            {hiddenFooter !== true && (
                <BasicFooter />
            )}
        </div>
    );
}
