import React from "react";
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Product1 from "../../../assets/uploads/product_1.png";
import Product2 from "../../../assets/uploads/product_2.png";
import Product3 from "../../../assets/uploads/product_3.png";
import Product4 from "../../../assets/uploads/product_4.png";
import Product5 from "../../../assets/uploads/product_5.png";
import Product6 from "../../../assets/uploads/product_6.png";
import Product7 from "../../../assets/uploads/product_7.png";
import Product8 from "../../../assets/uploads/product_8.png";
import Product9 from "../../../assets/uploads/product_9.png";
import Product10 from "../../../assets/uploads/product_10.png";
import ProductTitle1 from "../../../assets/uploads/product_title_1.svg";
import ProductTitle2 from "../../../assets/uploads/product_title_2.svg";
import ProductTitle3 from "../../../assets/uploads/product_title_3.svg";
import ProductTitle4 from "../../../assets/uploads/product_title_4.svg";
import ProductTitle5 from "../../../assets/uploads/product_title_5.svg";
import ProductTitle6 from "../../../assets/uploads/product_title_6.svg";
import ProductTitle7 from "../../../assets/uploads/product_title_7.svg";
import ProductTitle8 from "../../../assets/uploads/product_title_8.svg";
import ProductTitle9 from "../../../assets/uploads/product_title_9.svg";
import ProductTitle10 from "../../../assets/uploads/product_title_10.svg";

export default function Block2() {

    return (
        <div className="block2-products px-md-5-34 h-xs-auto">
            <Grid container sx={{
                height: "100%",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center"
            }}>
                <Grid item className="image-container" sx={{
                    marginBottom: "55px"
                }}>
                    <Link href="/producto-anti-okupa">
                        <img src={ProductTitle1} className="title-img" alet="" alt=""/>
                    </Link>
                    <Link href="/producto-anti-okupa">
                        <img src={Product1} alet="" alt=""/>
                    </Link>
                </Grid>
                <Grid item className="image-container" sx={{
                    marginBottom: "55px"
                }}>
                    <Link href="/producto-electronica">
                        <img src={ProductTitle2} className="title-img" alet="" alt=""/>
                    </Link>
                    <Link href="/producto-electronica">
                        <img src={Product2} alet="" alt=""/>
                    </Link>
                </Grid>
                <Grid item className="image-container" sx={{
                    marginBottom: "55px"
                }}>
                    <Link href="/producto-reja">
                        <img src={ProductTitle3} className="title-img" alet="" alt=""/>
                    </Link>
                    <Link href="/producto-reja">
                        <img src={Product3} alet="" alt=""/>
                    </Link>
                </Grid>
                <Grid item className="image-container" sx={{
                    marginBottom: "55px"
                }}>
                    <Link href="/producto-door">
                        <img src={ProductTitle4} className="title-img" alet="" alt=""/>
                    </Link>
                    <Link href="/producto-door">
                        <img src={Product4} alet="" alt=""/>
                    </Link>
                </Grid>
                <Grid item className="image-container" sx={{
                    marginBottom: "55px"
                }}>
                    <Link href="/producto-gravity">
                        <img src={ProductTitle5} className="title-img" alet="" alt=""/>
                    </Link>
                    <Link href="/producto-gravity">
                        <img src={Product5} alet="" alt=""/>
                    </Link>
                </Grid>
                <Grid item className="image-container" sx={{
                    marginBottom: "55px"
                }}>
                    <Link href="/producto-mot">
                        <img src={ProductTitle6} className="title-img" alet="" alt=""/>
                    </Link>
                    <Link href="/producto-mot">
                        <img src={Product6} alet="" alt=""/>
                    </Link>
                </Grid>
                <Grid item className="image-container" sx={{
                    marginBottom: "55px"
                }}>
                    <Link href="/producto-video-alarma">
                        <img src={ProductTitle7} className="title-img" alet="" alt=""/>
                    </Link>
                    <Link href="/producto-video-alarma">
                        <img src={Product7} alet="" alt=""/>
                    </Link>
                </Grid>
                <Grid item className="image-container" sx={{
                    marginBottom: "55px"
                }}>
                    <Link href="/producto-conan">
                        <img src={ProductTitle8} className="title-img" alet="" alt=""/>
                    </Link>
                    <Link href="/producto-conan">
                        <img src={Product8} alet="" alt=""/>
                    </Link>
                </Grid>
                <Grid item className="image-container" sx={{
                    marginBottom: "55px"
                }}>
                    <Link href="/producto-towers">
                        <img src={ProductTitle9} className="title-img" alet="" alt=""/>
                    </Link>
                    <Link href="/producto-towers">
                        <img src={Product9} alet="" alt=""/>
                    </Link>
                </Grid>
                <Grid item className="image-container" sx={{
                    marginBottom: "55px"
                }}>
                    <Link href="/producto-sentinel">
                        <img src={ProductTitle10} className="title-img" alet="" alt=""/>
                    </Link>
                    <Link href="/producto-sentinel">
                        <img src={Product10} alet="" alt=""/>
                    </Link>
                </Grid>
            </Grid>
        </div>
    )
}