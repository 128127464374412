import React from "react";
import Grid from '@mui/material/Grid';

export default function Carusel6() {

    return (
        <div className="carousel6-about px-md-20px px-lg-150px py-md-60px element-view-height h-xs-auto">
            <Grid container sx={{
                height: "100%",
                alignItems: "center",
                alignContent: "center"
            }}>
                <Grid item xs={12}>
                    <p>Todos nuestros productos tienen la posibilidad de <span>interconectarse entre sí</span> para aportar la <span>máxima seguridad</span> y un <span>control inteligente</span> de todos sus activos, lo que nos permite <span>reaccionar de manera ágil ante la ocupación</span>.</p>
                    <p>Gracias a nuestro <span>innovador ecosistema</span> podemos ofrecerle una <span>solución integral o modular adaptada</span> a sus necesidades. A nivel económico, ponemos a su disposición <span>diferentes modalidades</span>: venta, alquiler, packs…</p>
                </Grid>
            </Grid>
        </div>
    )
}